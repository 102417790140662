export const ACTIVITY_LIST_REQUEST = 'ACTIVITY_LIST_REQUEST';
export const ACTIVITY_LIST_SUCCESS = 'ACTIVITY_LIST_SUCCESS';
export const ACTIVITY_LIST_FAIL = 'ACTIVITY_LIST_FAIL';

export const ACTIVITY_SAVE_REQUEST = 'ACTIVITY_SAVE_REQUEST';
export const ACTIVITY_SAVE_SUCCESS = 'ACTIVITY_SAVE_SUCCESS';
export const ACTIVITY_SAVE_FAIL = 'ACTIVITY_SAVE_FAIL';

export const ACTIVITY_DELETE_REQUEST = 'ACTIVITY_DELETE_REQUEST';
export const ACTIVITY_DELETE_SUCCESS = 'ACTIVITY_DELETE_SUCCESS';
export const ACTIVITY_DELETE_FAIL = 'ACTIVITY_DELETE_FAIL';