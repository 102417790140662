import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { remember, contact } from '../actions/userActions';
import { RECAPTCHA_KEY } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function ContactScreen(props) {

  let content = {

    English: {
        title : "Send a message to Comparte Stereo",
        description : "Send us a contact message.  We will send you back a response.",
        contactForm : "Contact Form",
        name : "Name",
        email : "Email",
        message     : "Message",
        sendMessage     : "Send message...",
    },

    Español: {
        title : "Envía un mensaje a Comparte Stereo",
        description : "Envíanos un mensaje de contacto.  Te enviaremos una respuesta",
        contactForm : "Formulario de contacto",
        name : "Nombre",
        email : "Email",
        message     : "Mensaje",
        sendMessage     : "Enviar mensaje...",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    if (email && name && message) {
      if (isVerified) {
        e.preventDefault();
        dispatch(contact(email, name, message));
        alert("Message sent...  We'll send you a response, thank you!");
        props.history.push("/");
      } else {
        alert("Please verify you are a human!");
      }
    } else {
      alert("Please, enter the information!");
    }
  }

  function onChange(value) {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }

  ReactGA.initialize(GA_ID);

  return (
    <div className="wrapper">
            <MetaTags>
                <title>{content.title}</title>
                <meta name="description" content={content.description} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content={content.title} />
                <meta property="og:description" content={content.description} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="form">
              <form onSubmit={submitHandler} >
                <ul className="form-container">
                  <li>
                    <h2>{content.contactForm}</h2>
                  </li>
                  <li>
                          <label htmlFor="name">{content.name}</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                          ></input>
                        </li>
                  <li>
                    <label htmlFor="email">
                      {content.email}
                    </label>
                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
                    </input>
                  </li>
                  <li>
                          <label htmlFor="message">{content.message}</label>
                          <textarea
                            rows="10"
                            name="message"
                            value={message}
                            id="message"
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </li>
                  <li>
                    <button type="submit" className="button primary">{content.sendMessage}</button>
                  </li>

                </ul>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_KEY}
                  onChange={onChange}
                />
              </form>
            </div>
    </div>
  )
  
}
export default ContactScreen;