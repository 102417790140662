import {
    ATTRACTION_LIST_REQUEST, 
    ATTRACTION_LIST_SUCCESS, 
    ATTRACTION_LIST_FAIL, 
    ATTRACTION_DETAILS_REQUEST, 
    ATTRACTION_DETAILS_SUCCESS, 
    ATTRACTION_DETAILS_FAIL,
    ATTRACTION_SAVE_REQUEST,
    ATTRACTION_SAVE_SUCCESS,
    ATTRACTION_SAVE_FAIL,
    ATTRACTION_FILTER_REQUEST,
    ATTRACTION_FILTER_SUCCESS,
    ATTRACTION_FILTER_FAIL,
    ATTRACTION_DELETE_SUCCESS,
    ATTRACTION_DELETE_FAIL,
    ATTRACTION_DELETE_REQUEST,
} from "../constants/attractionConstants"
import { BACKEND_URL } from "../constants/generalConstants"
import axios from 'axios';

const listAttractions = () => async (dispatch, getState) => {
try {
    dispatch({ type: ATTRACTION_LIST_REQUEST });
    const { data } = await axios.get( BACKEND_URL + "/attractions");
    dispatch({ type: ATTRACTION_LIST_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: ATTRACTION_LIST_FAIL, payload: error.message });
    }
}

const filterAttractions = (location) => async (dispatch) => {
  try {
      dispatch({ type: ATTRACTION_FILTER_REQUEST });
      const { data } = await axios.get( BACKEND_URL + "/attractions/" + location);
      dispatch({ type: ATTRACTION_FILTER_SUCCESS, payload: data })
      }
      catch (error) {
          dispatch({ type: ATTRACTION_FILTER_FAIL, payload: error.message });
      }
  }

const detailsAttraction = (attractionId) => async (dispatch) => {
    try {
        dispatch({type: ATTRACTION_DETAILS_REQUEST, payload: attractionId});
        const {data} = await axios.get( BACKEND_URL + "/attraction/" + attractionId);
        dispatch({type: ATTRACTION_DETAILS_SUCCESS, payload: data});
    } catch (error) {
        dispatch({ type: ATTRACTION_DETAILS_FAIL, payload: error.message });
    }
}

const saveAttraction = (attraction) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATTRACTION_SAVE_REQUEST, payload: attraction });
      const {
        userSignin: { userInfo },
      } = getState();
      if (!attraction.id) {
        const { data } = await axios.post( BACKEND_URL + "/attraction/add", attraction, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: ATTRACTION_SAVE_SUCCESS, payload: data });
      } else {
        const { data } = await axios.put(
          BACKEND_URL + "/attraction/update",
          attraction,
          {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          }
        );
        dispatch({ type: ATTRACTION_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: ATTRACTION_SAVE_FAIL, payload: error.message });
    }
};

const deleteAttraction = (attractionId) => async (dispatch, getState) => {
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      dispatch({ type: ATTRACTION_DELETE_REQUEST, payload: attractionId });
      const { data } = await axios.delete( BACKEND_URL + "/attraction/delete/" + attractionId, {
        headers:
        { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
      });
      dispatch({ type: ATTRACTION_DELETE_SUCCESS, payload: data, success: true });
    } catch (error) {
      dispatch({ type: ATTRACTION_DELETE_FAIL, payload: error.message });
    }
};

export { listAttractions, detailsAttraction, deleteAttraction, saveAttraction, filterAttractions }