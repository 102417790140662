// Attractions Tips - Local host
//export const SITE_URL = "http://localhost:3000";
//export const RECAPTCHA_KEY = "6LeM1LgZAAAAACXSAeYmHYC5mckyg9qFsrgvUKSX";
//export const PAYPAL_ID = "AdRO2yt7F4EP4TXVAqEwK8rX1NgefboRcYm7PdjsZ3GiIzvxHyT1yNEVe9bZOsuIt7dsdsc7giuPx_v2";
//export const GA_ID = "UA-153092248-2";
export const PAYU_MERCHANT_ID = "508029";
export const PAYU_ACCOUNT_ID = "512321";
export const PAYU_API_KEY = "4Vj8eK4rloUd272L48hsrarnUA";
export const PAYU_URL = "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/";
export const PAYU_CONFIRMATION_URL = "https://www.compartestereo.com/services/comp/payment/confirmation";

// Comparte Stereo
export const SITE_NAME = "Comparte Stereo";
export const SITE_URL = "https://compartestereo.com";
export const META_TITLE = "Comparte Stereo...  Música online, promotora de arte y emprendimiento";
export const META_DESCRIPTION = "En Comparte Stereo encuentras un espacio para escuchar buena música y descubrir el arte de los emprendedores locales.";
export const RECAPTCHA_KEY = "6LcjQugpAAAAAGgN9JnmpN1_Rz2p7yMVsAdGJfpW";
export const BACKEND_URL = "https://compartestereo.com/services";
export const IMAGES_URL = "https://compartestereo.com/comparte/static/";
export const PAYPAL_ID = "Aes99V2W21yqLuaiJVd1uNQP-BWptA41xL71KNtaOpTP3HfcVKawD-M3ffRNz8IN5dFMYL9DbMd7ivbV";
export const GA_ID = "G-GY24Q8VDCZ";
//export const PAYU_MERCHANT_ID = "948434";
//export const PAYU_ACCOUNT_ID = "956008";
//export const PAYU_ACCOUNT_API_KEY = "eREMgT42MK9C1Qo9Fb8q5S4wdX";
//export const PAYU_URL = "https://checkout.payulatam.com/ppp-web-gateway-payu/";

// Cultura Stereo
//export const RECAPTCHA_KEY = "6LehncQZAAAAAMz_tlhXhGY9zzG_47V4KvIJ9NBf";
//export const BACKEND_URL = "https://lachiquitravel.com/cult";
//export const IMAGES_URL = "https://lachiquitravel.com/cult/static/images/";
//export const GA_ID = "UA-153092248-1";

// Paraglidinng Flow
//export const RECAPTCHA_KEY = "6LdWnsQZAAAAAGkqGaxTI6F1zMm6bKawOP5AXt3d";
//export const BACKEND_URL = "https://lachiquitravel.com/pgd";
//export const IMAGES_URL = "https://lachiquitravel.com/pgd/static/images/";
//export const GA_ID = "UA-153092248-3";
