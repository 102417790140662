import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  saveActivity,
  listActivities,
  deleteActivity,
} from '../actions/activityActions';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';

function ActivitiesScreen(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [uploading, setUploading] = useState(false);
  const activityList = useSelector((state) => state.activityList);
  const { loading, activities, error } = activityList;

  const activitySave = useSelector((state) => state.activitySave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = activitySave;

  const activityDelete = useSelector((state) => state.activityDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = activityDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    dispatch(listActivities());
    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (country) => {
    setModalVisible(true);
    setName(country.name);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      saveActivity({
        name,
      })
    );
  };

  const deleteHandler = (activity) => {
      dispatch(deleteActivity(activity.name));      
  };

  ReactGA.initialize(GA_ID);

  return (
    <div className="content content-margined">
      <div className="product-header">
        <h3>Activities</h3>
        <button className="button primary" onClick={() => openModal({})}>
          Create Activity
        </button>
      </div>
      {modalVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>Create Activity</h2>
              </li>
              <li>
                {loadingSave && <div>Loading...</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>

              <li>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {name ? 'Update' : 'Create'}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="button secondary"
                >
                  Back
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}

      { loading? <div>Loading...</div>:
            error? <div>{error}</div>:
            activities!=null? 
            (
              <div className="product-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((activity) => (
                      <tr key={activity.name}>
                        <td>{activity.name}</td>
                        <td>
                          <button className="button" onClick={() => openModal(activity)}>
                            Edit
                          </button>{' '}
                          <button
                            className="button"
                            onClick={() => { if (window.confirm("Are you sure to delete " + activity.name + "?")) deleteHandler(activity)}}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) :
            <div>No Activities</div>
      }
      </div>

  );
}

export default ActivitiesScreen;