import React from 'react';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import logo from '../resources/logo192.png';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function PolicyScreen({ props }) {

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>Policy</title>
                <meta name="description" content="Policy of Comparte Stereo" />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="Policy" />
                <meta property="og:description" content="Policy of Comparte Stereo" />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div  className="article">
                <h1>Our Policy:</h1>
                <a href="https://compartestereo.com/policy/terms-of-use"><h2>Terms of Use</h2></a><br></br>
                <a href="https://compartestereo.com/policy/privacy"><h2>Privacy Policy</h2></a><br></br>
                <a href="https://compartestereo.com/policy/cookie"><h2>Cookie Policy</h2></a><br></br>
                <a href="https://compartestereo.com/policy/disclaimer"><h2>Disclaimer</h2></a><br></br>
                <a href="https://compartestereo.com/policy/return"><h2>Return Policy</h2></a><br></br>
            </div>
        </div>
    )

}
export default PolicyScreen;