export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REMEMBER_REQUEST = 'USER_REMEMBER_REQUEST';
export const USER_REMEMBER_SUCCESS = 'USER_REMEMBER_SUCCESS';
export const USER_REMEMBER_FAIL = 'USER_REMEMBER_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_CONTACT_REQUEST = 'USER_CONTACT_REQUEST';
export const USER_CONTACT_SUCCESS = 'USER_CONTACT_SUCCESS';
export const USER_CONTACT_FAIL = 'USER_CONTACT_FAIL';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';