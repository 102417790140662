import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';
import { 
  attractionListReducer, 
  attractionDetailsReducer, 
  attractionSaveReducer, 
  attractionDeleteReducer,
  attractionFilterReducer,
} from './reducers/attractionReducers';
import { 
  tipListReducer, 
  tipDetailsReducer, 
  tipSaveReducer, 
  tipDeleteReducer,
  tipFilterReducer,
  shortTipListReducer, 
  shortTipDetailsReducer, 
  shortTipSaveReducer, 
  shortTipDeleteReducer,
  shortTipFilterReducer,
  shortTipAuthorFilterReducer,
} from './reducers/tipReducers';
import { 
  locationListReducer,
  locationDetailsReducer,
  locationDeleteReducer, 
  locationSaveReducer, 
  locationFilterReducer,
} from './reducers/locationReducers';
import { countryListReducer,
  countryDetailsReducer,
  countryDeleteReducer, 
  countrySaveReducer 
} from './reducers/countryReducers';
import { activityListReducer, 
  activityDeleteReducer, 
  activitySaveReducer 
} from './reducers/activityReducers';
import { cartReducer } from './reducers/cartReducers';
import { userSigninReducer, 
  userRegisterReducer, 
  userUpdateReducer, 
  userDeleteReducer, 
  userListReducer 
} from './reducers/userReducers';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  myOrderListReducer,
  orderListReducer,
  orderDeleteReducer,
} from './reducers/orderReducers';

const cartItems = Cookie.getJSON("cartItems") || [];
const userInfo = Cookie.getJSON('userInfo') || null;

const initialState = { 
  cart: { cartItems, booking: {}, payment: {} },
  userSignin: { userInfo },
};

const reducer = combineReducers({
  attractionList: attractionListReducer,
  attractionDetails: attractionDetailsReducer,
  attractionSave: attractionSaveReducer,
  attractionDelete: attractionDeleteReducer,
  attractionFilter: attractionFilterReducer,
  tipList: tipListReducer,
  tipDetails: tipDetailsReducer,
  tipSave: tipSaveReducer,
  tipDelete: tipDeleteReducer,
  tipFilter: tipFilterReducer,
  shortTipList: shortTipListReducer,
  shortTipDetails: shortTipDetailsReducer,
  shortTipSave: shortTipSaveReducer,
  shortTipDelete: shortTipDeleteReducer,
  shortTipFilter: shortTipFilterReducer,
  shortTipAuthorFilter: shortTipAuthorFilterReducer,
  locationList: locationListReducer,
  locationDetails: locationDetailsReducer,
  locationFilter: locationFilterReducer,
  locationDelete: locationDeleteReducer,
  locationSave: locationSaveReducer,
  countryList: countryListReducer,
  countryDetails: countryDetailsReducer,
  countryDelete: countryDeleteReducer,
  countrySave: countrySaveReducer,
  activityList: activityListReducer,
  activityDelete: activityDeleteReducer,
  activitySave: activitySaveReducer,
  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  userList: userListReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  myOrderList: myOrderListReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));
export default store;