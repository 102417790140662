import Axios from "axios";
import Cookie from 'js-cookie';
import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS, 
  USER_REGISTER_FAIL, 
  USER_LOGOUT, 
  USER_UPDATE_REQUEST, 
  USER_UPDATE_SUCCESS, 
  USER_UPDATE_FAIL,
  USER_REMEMBER_REQUEST,
  USER_REMEMBER_SUCCESS,
  USER_REMEMBER_FAIL,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_CONTACT_FAIL,
  USER_CONTACT_SUCCESS,
  USER_CONTACT_REQUEST,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL
} from "../constants/userConstants";
import { BACKEND_URL } from "../constants/generalConstants"

const update = ({ email, name, password, role }) => async (dispatch, getState) => {
  const { userSignin: { userInfo } } = getState();
  dispatch({ type: USER_UPDATE_REQUEST, payload: { email, name, password, role } });
  try {
    const { data } = await Axios.put( BACKEND_URL + "/user/update",
      { email, name, password, role }, {
      headers:
      { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
  }
}

const deleteUser = (email) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: USER_DELETE_REQUEST, payload: email });
    const { data } = await Axios.delete( BACKEND_URL + "/user/delete/" + email, {
      headers:
      { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: USER_DELETE_FAIL, payload: error.message });
  }
};

const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post( BACKEND_URL + "/users/signin", { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
  }
}

const remember = (email) => async (dispatch) => {
  dispatch({ type: USER_REMEMBER_REQUEST, payload: { email } });
  try {
    const { data } = await Axios.post( BACKEND_URL + "/users/validate", { email });
    dispatch({ type: USER_REMEMBER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REMEMBER_FAIL, payload: error.message });
  }
}

const contact = (email, name, message) => async (dispatch) => {
  dispatch({ type: USER_CONTACT_REQUEST, payload: { email, name, message } });
  try {
    const { data } = await Axios.post( BACKEND_URL + "/contact", { email, name, message });
    dispatch({ type: USER_CONTACT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_CONTACT_FAIL, payload: error.message });
  }
}

const register = (name, email, password) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, password } });
  try {
    const { data } = await Axios.post( BACKEND_URL + "/users/register", { name, email, password });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
  }
}

const logout = () => (dispatch) => {
  Cookie.remove("userInfo");
  dispatch({ type: USER_LOGOUT })
}

const listUsers = () => async (dispatch, getState) => {
  try {
      const {
        userSignin: { userInfo },
      } = getState();

      dispatch({ type: USER_LIST_REQUEST });
      const { data } = await Axios.get( BACKEND_URL + "/users", {
        headers:
        { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
      });
      dispatch({ type: USER_LIST_SUCCESS, payload: data })
      }
      catch (error) {
          dispatch({ type: USER_LIST_FAIL, payload: error.message });
      }
  }

export { signin, register, logout, update, remember, deleteUser, listUsers, contact };