import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useParams, Link  } from 'react-router-dom'
import './App.css';
import HomeScreen from './screens/HomeScreen';
import LocationScreen from './screens/LocationScreen';
import ActivityScreen from './screens/ActivityScreen';
import AttractionScreen from './screens/AttractionScreen';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import RegisterScreen from './screens/RegisterScreen';
import BookingScreen from './screens/BookingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrdersScreen from './screens/OrdersScreen';
import ProfileScreen from './screens/ProfileScreen';
import RememberScreen from './screens/RememberScreen';
import AttractionsScreen from './screens/AttractionsScreen';
import UsersScreen from './screens/UsersScreen';
import ActivitiesScreen from './screens/ActivitiesScreen';
import CountriesScreen from './screens/CountriesScreen';
import LocationsScreen from './screens/LocationsScreen';
import CreateTipScreen from './screens/CreateTipScreen';
import CreateShortTipScreen from './screens/CreateShortTipScreen';
import ContactScreen from './screens/ContactScreen';
import TipScreen from './screens/TipScreen';
import TermsScreen from './screens/TermsScreen';
import DisclaimerScreen from './screens/DisclaimerScreen';
import PrivacyPolicyScreen from './screens/PrivacyScreen';
import CookiePolicyScreen from './screens/CookieScreen';
import ReturnPolicyScreen from './screens/ReturnPolicyScreen';
import PolicyScreen from './screens/PolicyScreen';
import ShortTipScreen from './screens/ShortTipScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import ResponseScreen from './screens/ResponseScreen';
import Header from './components/Header';
import IdleTimer from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './actions/userActions';
import logo from './resources/Logo Comparte Stereo 96.png';
import CookieConsent from "react-cookie-consent";
import LanguageSelector from './components/LanguageSelector';
import ReactHlsPlayer from "react-hls-player";

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState("es");

  let content = {

    English: {
        ourPolicy : "Our Policy",
        becomeAhost : "Become a Host!",
    },
  
    Español: {
      ourPolicy : "Términos y Condiciones",
      becomeAhost : "Únete y comparte!",
    }
  
  }; 

  language === "en"
    ? (content = content.English)
    : (content = content.Español);


  const userSignin = useSelector(state=>state.userSignin);
  const {userInfo} = userSignin;
  const dispatch = useDispatch();

  const onIdle = () => {
    if (userInfo){
      dispatch(logout());
    }
  }

  function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
    window.location.assign('/' + language + '/' + window.location.pathname.split('/').slice(2).join('/') )
  }
  
  var userLanguage = window.navigator.userLanguage || window.navigator.language;

  return (
    <div className="text-center">

    <BrowserRouter>
        <IdleTimer
          key='idleTimer'
          startOnMount={ true }
          element={ document }
          onIdle={ () => onIdle({}) }
          timeout={ 1000 * 60 * 15 }>
        </IdleTimer>
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="Cookie-Consent-Attractions-Tips"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Este sitio usa cookies para mejorar la experiencia de usuario...{" "}
        </CookieConsent>        
        <div className="grid-container">
              <Header
                language={language}
                handleSetLanguage={language => {
                  setLanguage(language);
                  storeLanguageInLocalStorage(language);
                }}
              />
                <main className="main">
                    <div className="content">
                        <Switch>
                            <Route path={'/remember/:token'} component={SigninScreen} />
                            <Route path={'/remember'} component={RememberScreen} />
                            <Route path={'/orders'} component={OrdersScreen} />
                            <Route path={'/order/:id'} exact component={OrderScreen} />
                            <Route path={'/response/:id'} exact component={ResponseScreen} />
                            <Route path={'/profile'} component={ProfileScreen} />
                            <Route path={'/attractions'} component={AttractionsScreen} />
                            <Route path={'/new-tips'} component={CreateTipScreen} />
                            <Route path={'/create-review'} component={CreateShortTipScreen} />
                            <Route path={'/reviews/:attraction/:id'} component={ShortTipScreen} />
                            <Route path={'/contact'} component={ContactScreen} />
                            <Route path={'/users'} component={UsersScreen} />
                            <Route path={'/countries'} component={CountriesScreen} />
                            <Route path={'/activities'} component={ActivitiesScreen} />
                            <Route path={'/locations'} component={LocationsScreen} />
                            <Route path={'/booking'} component={BookingScreen} />
                            <Route path={'/payment'} component={PaymentScreen} />
                            <Route path={'/placeorder'} component={PlaceOrderScreen} />
                            <Route path={`/register`} component={RegisterScreen} />
                            <Route path={`/signin`} exact component={SigninScreen} />
                            <Route path={`/policy`} exact component={PolicyScreen} />
                            <Route path={`/policy/privacy`} exact component={PrivacyPolicyScreen} />
                            <Route path={`/policy/cookie`} exact component={CookiePolicyScreen} />
                            <Route path={`/policy/disclaimer`} exact component={DisclaimerScreen} />
                            <Route path={`/policy/return`} exact component={ReturnPolicyScreen} />
                            <Route path={`/policy/terms-of-use`} exact component={TermsScreen} />
                            <Route path={`/cart/:id?`} exact component={CartScreen} />
                            <Route path={`/`} exact component={HomeScreen} />
                            <Route path={`/:country`} exact component={LocationScreen} />
                            <Route path={`/:country/:location`} exact component={ActivityScreen} />
                            <Route path={`/:country/:location/:attraction`} exact component={AttractionScreen} />
                            <Route path={`/:country/:location/:attraction/:tip`} exact component={TipScreen} />
                            <Route path="*" component={NotFoundScreen} />
                        </Switch>
                    </div>
                    <div className="callToAction">
                        <h2>Regístrate y cuéntanos sobre tu emprendimiento</h2>
                        <Link to={'/signin?redirect=/create-review'}><h4>{content.becomeAhost}</h4></Link>
                        <div className="text-policy">
                          <Link to={'/policy'}><p>{content.ourPolicy}</p></Link><br></br>
                        </div>
                    </div>

                </main>
        </div>
    </BrowserRouter>

            <footer className="footer">
                <h2>Disfruta nuestra música online</h2>
                <div className='player'>
                  <ReactHlsPlayer
                    src={"https://compartestereo.com/hls/index.m3u8"}
                    autoPlay={false}
                    controls={true}
                    width="90%"
                    height="auto"
                  />
                </div>
                <div className="text-center">
                  <img src={logo} alt="Attraction Tips"></img><br></br>
                  © 2024
                </div>
            </footer>
                          </div>

  );
}

export default App;
