import {COUNTRY_LIST_REQUEST, 
    COUNTRY_LIST_SUCCESS, 
    COUNTRY_LIST_FAIL,
    COUNTRY_DETAILS_REQUEST, 
    COUNTRY_DETAILS_SUCCESS, 
    COUNTRY_DETAILS_FAIL,
    COUNTRY_SAVE_REQUEST,
    COUNTRY_SAVE_SUCCESS,
    COUNTRY_SAVE_FAIL,
    COUNTRY_DELETE_REQUEST,
    COUNTRY_DELETE_SUCCESS,
    COUNTRY_DELETE_FAIL} from "../constants/countryConstants"
import { BACKEND_URL } from "../constants/generalConstants"
import axios from 'axios';

const listCountries = () => async (dispatch) => {
try {
    dispatch({ type: COUNTRY_LIST_REQUEST });
    const { data } = await axios.get( BACKEND_URL + '/countries');
    dispatch({ type: COUNTRY_LIST_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: COUNTRY_LIST_FAIL, payload: error.message });
    }
}

const detailsCountry = (countryId) => async (dispatch) => {
  try {
      dispatch({type: COUNTRY_DETAILS_REQUEST, payload: countryId});
      const {data} = await axios.get( BACKEND_URL + "/country/" + countryId);
      dispatch({type: COUNTRY_DETAILS_SUCCESS, payload: data});
  } catch (error) {
      dispatch({ type: COUNTRY_DETAILS_FAIL, payload: error.message });
  }
}

const saveCountry = (country) => async (dispatch, getState) => {
    try {
      dispatch({ type: COUNTRY_SAVE_REQUEST, payload: country });
      const {
        userSignin: { userInfo },
      } = getState();
      if (!country.id) {
        const { data } = await axios.post( BACKEND_URL + '/country/add', country, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: COUNTRY_SAVE_SUCCESS, payload: data });
      } else {
        const { data } = await axios.put(
          BACKEND_URL + '/country/update',
          country,
          {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          }
        );
        dispatch({ type: COUNTRY_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: COUNTRY_SAVE_FAIL, payload: error.message });
    }
};

const deleteCountry = (countryId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: COUNTRY_DELETE_REQUEST, payload: countryId });
    const { data } = await axios.delete( BACKEND_URL + '/country/delete/' + countryId, {
      headers:
      { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
    });
    dispatch({ type: COUNTRY_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: COUNTRY_DELETE_FAIL, payload: error.message });
  }
};

export { listCountries, detailsCountry, saveCountry, deleteCountry }