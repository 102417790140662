import { 
  ATTRACTION_LIST_REQUEST, 
  ATTRACTION_LIST_SUCCESS, 
  ATTRACTION_LIST_FAIL, 
  ATTRACTION_DETAILS_REQUEST, 
  ATTRACTION_DETAILS_SUCCESS, 
  ATTRACTION_DETAILS_FAIL,
  ATTRACTION_SAVE_REQUEST,
  ATTRACTION_SAVE_SUCCESS,
  ATTRACTION_SAVE_FAIL,
  ATTRACTION_FILTER_REQUEST,
  ATTRACTION_FILTER_SUCCESS,
  ATTRACTION_FILTER_FAIL,
  ATTRACTION_DELETE_REQUEST,
  ATTRACTION_DELETE_SUCCESS,
  ATTRACTION_DELETE_FAIL,
} from "../constants/attractionConstants";

function attractionListReducer(state= {attractions:[]}, action){

    switch (action.type) {
        case    ATTRACTION_LIST_REQUEST:
            return  {loading: true};
        case    ATTRACTION_LIST_SUCCESS:
            return  {loading: false, attractions: action.payload}
        case    ATTRACTION_LIST_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
}

function attractionFilterReducer(state= {attractions:[]}, action){

  switch (action.type) {
      case    ATTRACTION_FILTER_REQUEST:
          return  {loading: true};
      case    ATTRACTION_FILTER_SUCCESS:
          return  {loading: false, attractions: action.payload}
      case    ATTRACTION_FILTER_FAIL:
          return  {loading: false, error: action.payload}
      default:
          return  state;
  }
}

function attractionDetailsReducer(state = { attraction: {} }, action) {

    switch (action.type) {
      case ATTRACTION_DETAILS_REQUEST:
        return { loading: true };
      case ATTRACTION_DETAILS_SUCCESS:
        return { loading: false, attraction: action.payload };
      case ATTRACTION_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state;
    }
  }

function attractionDeleteReducer(state = { attraction: {} }, action) {
  switch (action.type) {
    case ATTRACTION_DELETE_REQUEST:
      return { loading: true };
    case ATTRACTION_DELETE_SUCCESS:
      return { loading: false, attraction: action.payload, success: true };
    case ATTRACTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function attractionSaveReducer(state = { attraction: {} }, action) {
  switch (action.type) {
    case ATTRACTION_SAVE_REQUEST:
      return { loading: true };
    case ATTRACTION_SAVE_SUCCESS:
      return { loading: false, success: true, attraction: action.payload };
    case ATTRACTION_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {attractionListReducer, attractionDetailsReducer, attractionDeleteReducer, attractionSaveReducer, attractionFilterReducer}