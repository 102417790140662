import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import logo from '../resources/Logo Comparte Stereo 120.png';
import SideDrawer from './SideDrawer';
import UserAvatar from './UserAvatar';

class Header extends Component {

    state = {
        drawerOpen: false,
        headerShow: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        if (window.scrollY > 100) {
            this.setState({
                headerShow : true
            })
        } else {
            this.setState({
                headerShow : false
            })            
        }

    }

    toggleDrawer = (value) => {
        this.setState({
            drawerOpen: value
        })
    }

    render() {
        return (
            <AppBar
                position = "static"
                style={{
                    backgroundColor: 'white',
                    boxShadow:'none',
                    padding:'20px 20px',
                }}
            >

                <Toolbar className="toolbar">
                    <div className="brand">
                        <Link to={'/'}><img src= {this.state.headerShow ? logo : logo} alt="Logo Attractions Tips"/></Link>
                    </div>

                    <UserAvatar language={this.props.language}/>

                    <IconButton
                        aria-label="Menu"
                        style={{ color: '#00b445' }}
                        size = "small"
                        onClick={()=>this.toggleDrawer(true)}
                    >
                        <MenuIcon style={{ fontSize: 55 }}/>
                    </IconButton>

                    <SideDrawer
                        language={this.props.language}
                        open={this.state.drawerOpen}
                        onClose={()=>this.toggleDrawer(false)}
                        >
                    </SideDrawer>

                </Toolbar>

            </AppBar>
        );
    }

}

export default Header;
