import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  saveTip,
  listTips,
  deleteTip,
} from '../actions/tipActions';
import {
  listUsers,
} from '../actions/userActions';
import { listCountries } from '../actions/countryActions';
import { listActivities } from '../actions/activityActions';
import { filterLocations } from '../actions/locationActions';
import { filterAttractions } from '../actions/attractionActions';
import { BACKEND_URL } from "../constants/generalConstants"
import ReactGA from 'react-ga4';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IMAGES_URL } from '../constants/generalConstants';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function CreateTipScreen(props) {
  let content = {

    English: {
      tips : "Tips",
      createTip : "Create Tip",
      country : "Country",
      location : "Location",
      attraction : "Attraction",
      activity : "Activity",
      edit : "Edit",
      name : "Name",
      owner : "Owner",
      chooseUser     : "Choose an User",
      title     : "Title",
      description     : "Description",
      image     : "Image",
      imageAlt     : "Image Alt",
      article     : "Article",
      create     : "Create",
      update     : "Update",
      back     : "Back",
      delete     : "Delete",
    },

    Español: {
      tips : "Tips",
      createTip : "Crear Tip",
      country : "País",
      location : "Locación",
      attraction : "Atracción",
      activity : "Actividad",
      edit : "Editar",
      name : "Nombre",
      owner : "Propietario",
      chooseUser     : "Escoge un ususario",
      title     : "Título",
      description     : "Descripción",
      image     : "Imagen",
      imageAlt     : "Imagen Alt",
      article     : "Artículo",
      create     : "Crear",
      update     : "Actualizar",
      back     : "Atrás",
      delete     : "Borrar",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [modalVisible, setModalVisible] = useState(false);
  const [country, setCountry] = useState('');
  const [location, setLocation] = useState('');
  const [parent, setParent] = useState('');
  const [name, setName] = useState('');
  const [metaName, setMetaName] = useState('');
  const [metaNameEs, setMetaNameEs] = useState('');
  const [activity, setActivity] = useState('');
  const [image, setImage] = useState('');
  const [imageAlt, setImageAlt] = useState('');
  const [imageAltEs, setImageAltEs] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEs, setDescriptionEs] = useState('');
  const [article, setArticle] = useState('');
  const [articleEs, setArticleEs] = useState('');
  const [owner, setOwner] = useState('');
  const [uploading, setUploading] = useState(false);
  const tipList = useSelector((state) => state.tipList);
  const { loading, tips, error } = tipList;
  const countryList = useSelector((state) => state.countryList);
  const { loadingCountries, countries, errorCountries } = countryList;
  const locationFilter = useSelector(state => state.locationFilter);
  const {locations, loadingLocations, errorLocations} = locationFilter;
  const activityList = useSelector((state) => state.activityList);
  const { loadingActivities, activities, errorActivities } = activityList;
  const attractionFilter = useSelector(state => state.attractionFilter);
  const {attractions, loadingAttractions, errorAttractions} = attractionFilter;
  const userList = useSelector((state) => state.userList);
  const { loadingUsers, users, errorUsers } = userList;

  const tipSave = useSelector((state) => state.tipSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = tipSave;

  const tipDelete = useSelector((state) => state.tipDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = tipDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    dispatch(listTips());
    dispatch(listCountries());
    dispatch(listActivities());
    dispatch(listUsers());
    reloadLocations(country);
    reloadAttractions(location);

    if (country != '') {
      dispatch(filterLocations(country));
    }

    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (tip) => {
    setModalVisible(true);
    setParent(tip.parent);
    setName(tip.name);
    setActivity(tip.activity);
    setCountry(tip.country);
    setLocation(tip.location);
    setMetaName(tip.metaName);
    setMetaNameEs(tip.metaNameEs);
    setImage(tip.image);
    setImageAlt(tip.imageAlt);
    setImageAltEs(tip.imageAltEs);
    setDescription(tip.description);
    setDescriptionEs(tip.descriptionEs);
    setArticle(tip.article);
    setArticleEs(tip.articleEs);
    setOwner(tip.owner);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (name && parent) {
      var id = name.replace(/\s/g, '-') ;
      var result = id.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'') ;
      id = result.toLowerCase();
      id = id.replace('ñ', 'n') ;
  
      var path = '/' + country.toLowerCase() + '/' + location.toLowerCase() + '/' + parent.toLowerCase() + '/' + id;
  
      dispatch(
        saveTip({
          id: id,
          parent,
          country,
          location,
          path,
          name,
          metaName,
          metaNameEs,
          activity,
          image,
          imageAlt,
          imageAltEs,
          description,
          descriptionEs,
          article,
          articleEs,
          owner,
        })
      );
    } else {
      alert("Please, fill out the information!")
    } 
  };

  const deleteHandler = (tip) => {
    dispatch(deleteTip(tip.id));
  };

  const reloadLocations = (country) => {
    setCountry(country)
    dispatch(filterLocations(country));
    setParent()
  };

  const reloadAttractions = (location) => {
    setLocation(location)
    dispatch(filterAttractions(location));
    setParent()
  };

  function refreshImage(imgElement, imgURL){    
    var timestamp = new Date().getTime();    
    var el = document.getElementById(imgElement);  
    var queryString = "?t=" + timestamp;
    el.src = IMAGES_URL + imgURL + queryString;    
  }  

  const rotateCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/cw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const rotateCCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/ccw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post( BACKEND_URL + '/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const editorConfiguration = {
    heading: {
      options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      ]
    },
  };

  ReactGA.initialize(GA_ID);

  return (
    <div className="wrapper">
            <MetaTags>
                <title>Create a new ATTRACTION TIP</title>
                <meta name="description" content="Sign in to book ATTRACTIONS or post TIPS of places of interest." />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="Create a new ATTRACTION TIP" />
                <meta property="og:description" content="Sign in to book ATTRACTIONS or post TIPS of places of interest." />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="content-margined">
              <div className="product-header">
                <h3>{content.tips}</h3>
                <button className="button primary" onClick={() => openModal({})}>
                  {content.createTip}
                </button>
              </div>
              {modalVisible && (
                <div className="form">
                  <form onSubmit={submitHandler}>
                      <ul className="form-container">
                        <li>
                          <h2>{content.createTip}</h2>
                        </li>
                        <li>
                          {loadingSave && <div>Loading...</div>}
                          {errorSave && <div>{errorSave}</div>}
                        </li>

                          <li>
                            <label htmlFor="owner">{content.owner}</label>
                            {users!=null? 
                              (
                                <select value={owner} onChange={(e) => setOwner(e.target.value)}>
                                    {users.map((user) => (
                                        <option key={user.email} value={user.email}>{user.email}</option>
                                    ))}
                                </select>
                              ) :
                              <div>No users...</div>}
                          </li>
                        <li>
                          <label htmlFor="activity">{content.activity}</label>
                          {activities!=null? 
                          (
                            <select value={activity} onChange={(e) => setActivity(e.target.value)} onClick={(e) => setActivity(e.target.value)}>                    
                              {activities.map((activity) => (
                                  <option key={activity.name} value={activity.name}>{activity.name}</option>
                              ))}
                            </select>
                          ) :
                          <div>No Activities</div>}
                        </li>
                        <li>
                          <label htmlFor="country">{content.country}</label>
                          {countries!=null? 
                          (
                            <select value={country} onChange={(e) => reloadLocations(e.target.value)}>
                                {countries.map((country) => (
                                    <option key={country.id} value={country.id}>{country.name}</option>
                                ))}
                            </select>
                          ) :
                          <div>No Countries</div>}
                        </li>
                        <li>
                          <label htmlFor="location">{content.location}</label>
                          {locations!=null? 
                          (
                            <select value={location} onChange={(e) => reloadAttractions(e.target.value)}>                    
                              {locations.map((location) => (
                                  <option key={location.id} value={location.id}>{location.name}</option>
                              ))}
                            </select>
                          ) :
                          <div>No Locations</div>}
                        </li>
                        <li>
                          <label htmlFor="attraction">{content.attraction}</label>
                          {attractions!=null? 
                          (
                            <select value={parent} onChange={(e) => setParent(e.target.value)} onClick={(e) => setParent(e.target.value)}>                    
                              {attractions.map((attraction) => (
                                  <option key={attraction.id} value={attraction.id}>{attraction.name}</option>
                              ))}
                            </select>
                          ) :
                          <div>No Attractions</div>}
                        </li>
                        <li>
                          <label htmlFor="name">{content.name}</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                          ></input>
                        </li>
                        {
                          props.language === "en"
                          ?  <li>
                                <label htmlFor="metaName">{content.title} ({props.language})</label>
                                <textarea
                                  rows="5"
                                  name="metaName"
                                  value={metaName}
                                  id="metaName"
                                  onChange={(e) => setMetaName(e.target.value)}
                                ></textarea>
                              </li>
                          :  <li>
                                <label htmlFor="metaNameEs">{content.title} ({props.language})</label>
                                <textarea
                                  rows="5"
                                  name="metaNameEs"
                                  value={metaNameEs}
                                  id="metaNameEs"
                                  onChange={(e) => setMetaNameEs(e.target.value)}
                                ></textarea>
                              </li>
                        }
                        {
                          props.language === "en"
                          ?  <li>
                              <label htmlFor="description">{content.description} ({props.language})</label>
                              <textarea
                                rows="8"
                                name="description"
                                value={description}
                                id="description"
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </li>
                          :  <li>
                              <label htmlFor="descriptionEs">{content.description} ({props.language})</label>
                              <textarea
                                rows="8"
                                name="descriptionEs"
                                value={descriptionEs}
                                id="descriptionEs"
                                onChange={(e) => setDescriptionEs(e.target.value)}
                              ></textarea>
                            </li>
                        }

                        <li>
                          <div className="showImage">
                            <img id="articlePic" src={ IMAGES_URL + image} alt="Tip picture" className="showImage"/>
                            <b onClick={() => { rotateCCWHandler(image) }}>Left</b>
                            <b> -Rotate- </b>
                            <b onClick={() => { rotateCWHandler(image) }}>Right</b>
                          </div> 
                        </li>
                        <li>
                          <label htmlFor="image">{content.image}</label>
                          <input
                            type="text"
                            name="image"
                            value={image}
                            id="image"
                            onChange={(e) => setImage(e.target.value)}
                          ></input>
                          <input type="file" onChange={uploadFileHandler}></input>
                          {uploading && <h2>Uploading...</h2>}
                        </li>
                        {
                          props.language === "en"
                          ?  <li>
                              <label htmlFor="imageAlt">{content.imageAlt} ({props.language})</label>
                              <input
                                type="text"
                                name="imageAlt"
                                value={imageAlt}
                                id="imageAlt"
                                onChange={(e) => setImageAlt(e.target.value)}
                              ></input>
                            </li>
                          :  <li>
                              <label htmlFor="imageAltEs">{content.imageAlt} ({props.language})</label>
                              <input
                                type="text"
                                name="imageAltEs"
                                value={imageAltEs}
                                id="imageAltEs"
                                onChange={(e) => setImageAltEs(e.target.value)}
                              ></input>
                            </li>
                        }
                        {
                          props.language === "en"
                          ?  <li>
                              <label htmlFor="article">{content.article} ({props.language})</label>
                              <CKEditor 
                                  editor={ ClassicEditor }
                                  config={editorConfiguration}
                                  data={article}
                                  onChange={ ( event, editor ) => {
                                      const data = editor.getData();
                                      setArticle(data);
                                  } }
                              />
                            </li>
                          :  <li>
                              <label htmlFor="articleEs">{content.article} ({props.language})</label>
                              <CKEditor 
                                  editor={ ClassicEditor }
                                  config={editorConfiguration}
                                  data={articleEs}
                                  onChange={ ( event, editor ) => {
                                      const data = editor.getData();
                                      setArticleEs(data);
                                  } }
                              />
                            </li>
                        }

                        
                        <li>
                          <button type="submit" className="button primary">
                            {name ? 'Update' : 'Create'}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => setModalVisible(false)}
                            className="button secondary"
                          >
                            {content.back}
                          </button>
                        </li>
                      </ul>
                  </form>
                </div>
              )}

              { loading? <div>Loading...</div>:
                    error? <div>{error}</div>:
                    tips!=null? 
                    (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{content.name}</th>
                              <th>{content.activity}</th>
                              <th>{content.country}</th>
                              <th>{content.location}</th>
                              <th>{content.attraction}</th>
                              <th>{content.owner}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tips.map((tip) => (
                              <tr key={tip.id}>
                                <td>{tip.name}</td>
                                <td>{tip.activity}</td>
                                <td>{tip.country}</td>
                                <td>{tip.location}</td>
                                <td>{tip.parent}</td>
                                <td>{tip.owner}</td>
                                <td>
                                  <button className="button" onClick={() => openModal(tip)}>
                                    {content.edit}
                                  </button>{' '}
                                  <button
                                    className="button"
                                    onClick={() => { if (window.confirm("Are you sure to delete " + tip.name + "?")) deleteHandler(tip)} }
                                  >
                                    {content.delete}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                    ) : 
                    <div>No Tips</div>
              }
            </div>
    </div>
  );
}

export default CreateTipScreen;