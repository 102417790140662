import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import logo from '../resources/logo192.png';
import { SITE_NAME, SITE_URL, META_TITLE, META_DESCRIPTION, GA_ID } from '../constants/generalConstants';

function NotFoundScreen({ props }) {

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>{META_TITLE}</title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content={META_TITLE} />
                <meta property="og:description" content={META_DESCRIPTION} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div  className="article">
                <img className="responsive" src={logo} alt="Attraction Tips"></img>
                <h1>The page you're looking for doesn't exist!</h1>
            </div>
        </div>
    )

}
export default NotFoundScreen;