import {LOCATION_LIST_REQUEST, 
    LOCATION_LIST_SUCCESS, 
    LOCATION_LIST_FAIL,
    LOCATION_DETAILS_REQUEST,
    LOCATION_DETAILS_SUCCESS,
    LOCATION_DETAILS_FAIL,
    LOCATION_SAVE_REQUEST,
    LOCATION_SAVE_SUCCESS,
    LOCATION_SAVE_FAIL,
    LOCATION_FILTER_REQUEST,
    LOCATION_FILTER_SUCCESS,
    LOCATION_FILTER_FAIL,
    LOCATION_DELETE_REQUEST,
    LOCATION_DELETE_SUCCESS,
    LOCATION_DELETE_FAIL} from "../constants/locationConstants"
import { BACKEND_URL } from "../constants/generalConstants"
import axios from 'axios';

const listLocations = () => async (dispatch) => {
try {
    dispatch({ type: LOCATION_LIST_REQUEST });
    const { data } = await axios.get( BACKEND_URL + "/locations");
    dispatch({ type: LOCATION_LIST_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: LOCATION_LIST_FAIL, payload: error.message });
    }
}

const detailsLocation = (locationId) => async (dispatch) => {
  try {
      dispatch({type: LOCATION_DETAILS_REQUEST, payload: locationId});
      const {data} = await axios.get( BACKEND_URL + "/location/" + locationId);
      dispatch({type: LOCATION_DETAILS_SUCCESS, payload: data});
  } catch (error) {
      dispatch({ type: LOCATION_DETAILS_FAIL, payload: error.message });
  }
}

const filterLocations = (country) => async (dispatch) => {
  try {
      dispatch({ type: LOCATION_FILTER_REQUEST });
      const { data } = await axios.get( BACKEND_URL + "/locations/" + country);
      dispatch({ type: LOCATION_FILTER_SUCCESS, payload: data })
      }
      catch (error) {
          dispatch({ type: LOCATION_FILTER_FAIL, payload: error.message });
      }
  }
  
const saveLocation = (location) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOCATION_SAVE_REQUEST, payload: location });
      const {
        userSignin: { userInfo },
      } = getState();
      if (!location.id) {
        const { data } = await axios.post( BACKEND_URL + "/location/add", location, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: LOCATION_SAVE_SUCCESS, payload: data });
      } else {
        const { data } = await axios.put(
          BACKEND_URL + "/location/update",
          location,
          {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          }
        );
        dispatch({ type: LOCATION_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: LOCATION_SAVE_FAIL, payload: error.message });
    }
};

const deleteLocation = (locationId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: LOCATION_DELETE_REQUEST, payload: locationId });
    const { data } = await axios.delete( BACKEND_URL + "/location/delete/" + locationId, {
      headers:
      { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
    });
    dispatch({ type: LOCATION_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: LOCATION_DELETE_FAIL, payload: error.message });
  }
};

export { listLocations, detailsLocation, saveLocation, deleteLocation, filterLocations }