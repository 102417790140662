export const LOCATION_LIST_REQUEST = 'LOCATION_LIST_REQUEST';
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS';
export const LOCATION_LIST_FAIL = 'LOCATION_LIST_FAIL';

export const LOCATION_DETAILS_REQUEST = 'LOCATION_DETAILS_REQUEST';
export const LOCATION_DETAILS_SUCCESS = 'LOCATION_DETAILS_SUCCESS';
export const LOCATION_DETAILS_FAIL = 'LOCATION_DETAILS_FAIL';

export const LOCATION_SAVE_REQUEST = 'LOCATION_SAVE_REQUEST';
export const LOCATION_SAVE_SUCCESS = 'LOCATION_SAVE_SUCCESS';
export const LOCATION_SAVE_FAIL = 'LOCATION_SAVE_FAIL';

export const LOCATION_DELETE_REQUEST = 'LOCATION_DELETE_REQUEST';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_FAIL = 'LOCATION_DELETE_FAIL';

export const LOCATION_FILTER_REQUEST = 'LOCATION_FILTER_REQUEST';
export const LOCATION_FILTER_SUCCESS = 'LOCATION_FILTER_SUCCESS';
export const LOCATION_FILTER_FAIL = 'LOCATION_FILTER_FAIL';