import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  update,
  listUsers,
  deleteUser,
} from '../actions/userActions';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';

function UsersScreen(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const userList = useSelector((state) => state.userList);
  const { loading, users, error } = userList;

  const userSave = useSelector((state) => state.userUpdate);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = userSave;

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = userDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    dispatch(listUsers());
    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (user) => {
    setModalVisible(true);
    setName(user.name);
    setEmail(user.email);
    setPassword(user.password);
    setRole(user.role);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      update({
        name,
        email,
        password,
        role,
      })
    );
    dispatch(listUsers());
    setModalVisible(false);
  };

  const deleteHandler = (user) => {
    dispatch(deleteUser(user.email));
    dispatch(listUsers());
  };

  ReactGA.initialize(GA_ID);

  return (
    <div className="content content-margined">
      <div className="product-header">
        <h3>Users</h3>
        <button className="button primary" onClick={() => openModal({})}>
          Create User
        </button>
      </div>
      {modalVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>{email ? 'Update User' : 'Create User'}</h2>
              </li>
              <li>
                {loadingSave && <div>Loading...</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>

              <li>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="password">Password</label>
                <input
                  type="text"
                  name="password"
                  value={password}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="role">Role</label>
                <input
                  type="text"
                  name="role"
                  value={role}
                  id="role"
                  onChange={(e) => setRole(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {email ? 'Update' : 'Create'}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="button secondary"
                >
                  Back
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}

      { loading? <div>Loading...</div>:
            error? <div>{error}</div>:
            !users? <div>No users</div>:

            (
              <div className="product-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Password</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.email}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.password}</td>
                        <td>{user.role}</td>
                        <td>
                          <button className="button" onClick={() => openModal(user)}>
                            Edit
                          </button>{' '}
                          <button
                            className="button"
                            onClick={() => { if (window.confirm("Are you sure to delete " + user.name + "?")) deleteHandler(user)} }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
            )
      }
      </div>

  );
}

export default UsersScreen;