export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';

export const COUNTRY_DETAILS_REQUEST = 'COUNTRY_DETAILS_REQUEST';
export const COUNTRY_DETAILS_SUCCESS = 'COUNTRY_DETAILS_SUCCESS';
export const COUNTRY_DETAILS_FAIL = 'COUNTRY_DETAILS_FAIL';

export const COUNTRY_SAVE_REQUEST = 'COUNTRY_SAVE_REQUEST';
export const COUNTRY_SAVE_SUCCESS = 'COUNTRY_SAVE_SUCCESS';
export const COUNTRY_SAVE_FAIL = 'COUNTRY_SAVE_FAIL';

export const COUNTRY_DELETE_REQUEST = 'COUNTRY_DELETE_REQUEST';
export const COUNTRY_DELETE_SUCCESS = 'COUNTRY_DELETE_SUCCESS';
export const COUNTRY_DELETE_FAIL = 'COUNTRY_DELETE_FAIL';