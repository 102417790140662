export const TIP_LIST_REQUEST = 'TIP_LIST_REQUEST';
export const TIP_LIST_SUCCESS = 'TIP_LIST_SUCCESS';
export const TIP_LIST_FAIL = 'TIP_LIST_FAIL';

export const TIP_DETAILS_REQUEST = 'TIP_DETAILS_REQUEST';
export const TIP_DETAILS_SUCCESS = 'TIP_DETAILS_SUCCESS';
export const TIP_DETAILS_FAIL = 'TIP_DETAILS_FAIL';

export const TIP_SAVE_REQUEST = 'TIP_SAVE_REQUEST';
export const TIP_SAVE_SUCCESS = 'TIP_SAVE_SUCCESS';
export const TIP_SAVE_FAIL = 'TIP_SAVE_FAIL';

export const TIP_DELETE_REQUEST = 'TIP_DELETE_REQUEST';
export const TIP_DELETE_SUCCESS = 'TIP_DELETE_SUCCESS';
export const TIP_DELETE_FAIL = 'TIP_DELETE_FAIL';

export const TIP_FILTER_REQUEST = 'TIP_FILTER_REQUEST';
export const TIP_FILTER_SUCCESS = 'TIP_FILTER_SUCCESS';
export const TIP_FILTER_FAIL = 'TIP_FILTER_FAIL';

export const SHORT_TIP_LIST_REQUEST = 'SHORT_TIP_LIST_REQUEST';
export const SHORT_TIP_LIST_SUCCESS = 'SHORT_TIP_LIST_SUCCESS';
export const SHORT_TIP_LIST_FAIL = 'SHORT_TIP_LIST_FAIL';

export const SHORT_TIP_DETAILS_REQUEST = 'SHORT_TIP_DETAILS_REQUEST';
export const SHORT_TIP_DETAILS_SUCCESS = 'SHORT_TIP_DETAILS_SUCCESS';
export const SHORT_TIP_DETAILS_FAIL = 'SHORT_TIP_DETAILS_FAIL';

export const SHORT_TIP_SAVE_REQUEST = 'SHORT_TIP_SAVE_REQUEST';
export const SHORT_TIP_SAVE_SUCCESS = 'SHORT_TIP_SAVE_SUCCESS';
export const SHORT_TIP_SAVE_FAIL = 'SHORT_TIP_SAVE_FAIL';

export const SHORT_TIP_DELETE_REQUEST = 'SHORT_TIP_DELETE_REQUEST';
export const SHORT_TIP_DELETE_SUCCESS = 'SHORT_TIP_DELETE_SUCCESS';
export const SHORT_TIP_DELETE_FAIL = 'SHORT_TIP_DELETE_FAIL';

export const SHORT_TIP_FILTER_REQUEST = 'SHORT_TIP_FILTER_REQUEST';
export const SHORT_TIP_FILTER_SUCCESS = 'SHORT_TIP_FILTER_SUCCESS';
export const SHORT_TIP_FILTER_FAIL = 'SHORT_TIP_FILTER_FAIL';

export const SHORT_TIP_AUTHOR_FILTER_REQUEST = 'SHORT_TIP_AUTHOR_FILTER_REQUEST';
export const SHORT_TIP_AUTHOR_FILTER_SUCCESS = 'SHORT_TIP_AUTHOR_FILTER_SUCCESS';
export const SHORT_TIP_AUTHOR_FILTER_FAIL = 'SHORT_TIP_AUTHOR_FILTER_FAIL';