import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { listCountries } from '../actions/countryActions';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import parse from 'html-react-parser';
import logo from '../resources/Logo Comparte Stereo 300.png';
import sticker from '../resources/Sticker Comparte Stereo.png';
import {
    listShortTips,
  } from '../actions/tipActions';
import Flickity from 'react-flickity-component';
import "flickity/css/flickity.css";
import "../resources/carousel.min.css";
import { SITE_NAME, SITE_URL, META_TITLE, META_DESCRIPTION, GA_ID } from '../constants/generalConstants';

function HomeScreen(props) {

    let content = {

        English: {
            metaTitle : "ATTRACTIONS TIPS with the best tour guides in the world!",
            metaDescription: "TIPS to travel around the world and discover the tourist ATTRACTIONS that will make your dream trip come true.  Local tour guides will share with you the best destinations and things to do.",
            title: "Discover destinations and ATTRACTIONS TIPS with the advice of a local tour guide!",
            description:
                "In Attractions Tips, we are creating a tour guide network around the world.  The travel sites are enriched with the presence of a local.  The tips, the stories and the jokes.  It is about culture, travel and talking to the people.  Book your activity and enjoy traveling.",
            article:
                `<h2>
                    Colombia, the start point of the trip
                </h2>
        
                <p>
                    The origin of this project is Colombia, and from here, we will start seeking special people to do cool stuff.  <a href='/en/colombia'>Visit Colombia and get in love with nature and diversity</a>.
                </p>`,
            readMore: "Read more"
        },
      
        Español: {
            metaTitle: "Comparte Stereo...  Música online, promotora de arte y emprendimiento",
            metaDescription: "En Comparte Stereo encuentras un espacio para escuchar buena música y descubrir el arte de los emprendedores locales", 
            title: "Música online, promotora de arte y emprendimiento",
            description:
                "Escucha buena música, descubre los emprendimientos locales y apoya sus creadores.  La pasión convierte en arte sus productos y servicios.",
            article:
                `<h2>
                    Categorias
                </h2>

                <p>
                    Descubre la variedad de emprendimientos por categorias.
                </p>`,
            readMore: "Leer más"
        }
      
      }; 

        props.language === "en"
    ? (content = content.English)
    : (content = content.Español);

    const countryList = useSelector(state => state.countryList);
    const {countries, loading, error} = countryList;
    const dispatch = useDispatch();
    const shortTipList = useSelector((state) => state.shortTipList);
    const { loadingTips, tips, errorTips } = shortTipList;
    const parse = require('html-react-parser');

    useEffect(() => {
        if (props.match.params.lang != props.language) { props.handleSetLanguage(props.match.params.lang) }
        dispatch(listCountries());
        dispatch(listShortTips());
        return () => {

        };
    }, [])

    const handleAddToCart = () =>{
            props.history.push("/cart/")
    };

    const linkList = loading? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                     error? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                     countries!=null? 
                        (
                        countries.map((country) => {
                        return (
                            <li key={country.id} value={country.id}>
                                <img className="product-image" src={ IMAGES_URL + country.image} alt={props.language=="en"? country.imageAlt : country.imageAltEs}></img>
                                <h3  className="product-name">{props.language=="en"? country.metaName : country.metaNameEs}</h3>
                                <div className="product-short"><p>{props.language=="en"? country.description : country.descriptionEs}</p></div>
                                <div className="callToAction">
                                    <a href={`/${props.language}/${country.id}`}>
                                        <h4>{content.readMore}</h4>
                                    </a>
                                </div>
                            </li>
                        );
                    })
                    ) :
                    <div>No Countries</div>

    const articlePic = loading? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    error? <img className="responsive" src={sticker} alt={SITE_NAME}></img>:
                    !countries? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    <img className="responsive" src={logo} alt={SITE_NAME}></img>

    const article = parse(String(content.article));

    const flickityOptions = {
        initialIndex: 2,
        adaptiveHeight: true,
        wrapAround: true,
        pageDots: false,
        prevNextButtons: true,
        autoPlay: 7000,
    }

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>{content.metaTitle}</title>
                <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                    href={props.language=="en"?SITE_URL + "/es":SITE_URL + "/en"} />
                <link rel="alternate" hreflang="x-default"
                    href={SITE_URL + "/en"} />
                <meta name="description" content={content.metaDescription} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content={content.metaTitle} />
                <meta property="og:description" content={content.metaDescription} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div  className="article">
                <h1>
                    {content.title}
                </h1>
                {tips!=null? 
                    (
                        <Flickity
                            className={'carousel'} // default ''
                            elementType={'div'} // default 'div'
                            options={flickityOptions} // takes flickity options {}
                            disableImagesLoaded={false} // default false
                            reloadOnUpdate // default false
                            static // default false
                            >
                            {tips.map((shortTip) => (
                                <div className="carousel" key={ shortTip.id }>
                                    <img id={ shortTip.id } src={ IMAGES_URL + shortTip.image} alt={shortTip.title}/>
                                </div>
                            ))}
                        </Flickity>
                    ) : 
                    <div>{ articlePic }</div>
                }

                <p>
                    {content.description}
                </p>

                <p>
                    &nbsp;
                </p>

                {article}
            </div>
            <ul className="products"> {linkList} </ul>

        </div>
    )

}
export default HomeScreen;