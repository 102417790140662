import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import { logout } from '../actions/userActions';
import { GA_ID } from '../constants/generalConstants';

function PlaceOrderScreen(props) {

  let content = {

    English: {
      details : "Booking details",
      payment : "Payment",
      paymentMethod : "Payment Method",
      cart : "Booking Cart",
      name : "Name",
      contactNumber : "Contact number",
      nationality : "Nationality",
      groupDetails : "Group details",
      date : "Booking date",
      people : "People",
      placeOrder : "Place booking",
      summary : "Order Summary",
      total : "Order Total",
      price : "Price each",
    },

    Español: {
      details : "Detalles de la reserva",
      payment : "Pago",
      paymentMethod : "Método de pago",
      cart : "Lista de reserva",
      name : "Nombre",
      contactNumber : "Número de contacto",
      nationality : "Nacionalidad",
      groupDetails : "Detalles del grupo",
      date : "Fecha de reserva",
      people : "Personas",
      placeOrder : "Confirmar reserva",
      summary : "Resumen de la reserva",
      total : "Total",
      price : "Precio cada uno",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const cart = useSelector(state => state.cart);
  const orderCreate = useSelector(state => state.orderCreate);
  var { loading, success, error, order } = orderCreate;
  const userSignin = useSelector(state=>state.userSignin);
  const {userInfo} = userSignin;

  const { cartItems, booking, payment } = cart;
  if (!booking.bookingName || !booking.contactNumber) {
    props.history.push("/booking");
  } else if (!payment.paymentMethod) {
    props.history.push("/payment");
  }
  const itemsPrice = cartItems.reduce((a, c) => a + c.price * c.qty, 0);
  const bookingPrice = 0;
  const taxPrice = 0;
  const totalPrice = itemsPrice + bookingPrice + taxPrice;

  const dispatch = useDispatch();

  const placeOrderHandler = () => {
    if (userInfo){
      // create an order
      var date = new Date();
      var id =  date.getTime();
      var idString = id.toString(); 
      var bookingName = booking.bookingName 
      var contactNumber = booking.contactNumber 
      var nationality = booking.nationality
      var groupDetails = booking.groupDetails 
      var paymentMethod = payment.paymentMethod
      var isDelivered = false
      var deliveredAt = ""
      var isPaid = false
      var paidAt = ""
      var owner = ""

      dispatch(createOrder({
        id,
        idString,
        owner,
        bookingName, 
        contactNumber,
        nationality,
        groupDetails, 
        paymentMethod,
        itemsPrice,
        bookingPrice,
        taxPrice,
        totalPrice,
        isDelivered,
        deliveredAt,
        isPaid,
        paidAt,
        orderItems: cartItems
      }));
    } else {
      props.history.push('/' + props.language + "/signin?redirect=placeorder");
    }
  }

  useEffect(() => {
    if (success) {
      props.history.push('/' + props.language + '/order/' + order.Id);
    } 
    if (error) {
      error = !error;
      dispatch(logout());
      alert('There was a problem with the order placement!');
      props.history.push('/' + props.language + '/cart');
    }

    return () => {
      //
    };
  }, [success, error]);

  ReactGA.initialize(GA_ID);

  return <div>
    <CheckoutSteps step1 step2 language={props.language} {...props}></CheckoutSteps>
    <div className="placeorder">
      <div className="placeorder-info">
        <div>
          <h3>
            {content.details}
          </h3>
          <div>
            {content.name}: {cart.booking.bookingName} // {content.contactNumber}: {cart.booking.contactNumber} // 
            {content.nationality}: {cart.booking.nationality} // {content.groupDetails}: {cart.booking.groupDetails}.
          </div>
        </div>
        <div>
          <h3>{content.payment}</h3>
          <div>
          {content.paymentMethod}: {cart.payment.paymentMethod}
          </div>
        </div>
        <div>
          <ul className="cart-list-container">
            <li>
              <h3>
              {content.cart}
          </h3>
              <div>
                {content.price}
          </div>
            </li>
            {
              cartItems.length === 0 ?
                <div>
                  Cart is empty
          </div>
                :
                cartItems.map(item =>
                  <li key={item.name} value={item.name}>
                    <div className="cart-image">
                      <img src={ IMAGES_URL + item.image} alt="attraction" />
                    </div>
                    <div className="cart-name">
                      <div>
                        <Link to={item.path}>
                          {item.name}
                        </Link>
                      </div>
                      <div>
                        {content.date}: {item.date}
                      </div>
                      <div>
                        {content.people}: {item.qty}
                      </div>
                    </div>
                    <div className="cart-price">
                      ${item.price}
                    </div>
                  </li>
                )
            }
          </ul>
        </div>
        <div className="placeorder-action">
        <ul>
          <li>
            <button className="button primary full-width" onClick={placeOrderHandler} >{content.placeOrder}</button>
          </li>
          <li>
            <div>{content.total}</div>
            <div>${totalPrice} USD</div>
          </li>
        </ul>



      </div>

      
      </div>

    </div>
  </div>

}

export default PlaceOrderScreen;