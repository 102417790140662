import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { detailsAttraction } from '../actions/attractionActions';
import { IMAGES_URL } from '../constants/generalConstants';
import { detailsTip } from '../actions/tipActions';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import logo from '../resources/logo192.png';
import DayPicker from 'react-day-picker';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { SITE_NAME, SITE_URL, META_TITLE, GA_ID } from '../constants/generalConstants';

function TipScreen(props) {

    let content = {

        English: {
          location : "Location",
          status : "Status",
          available : "Available",
          notAvailable : "Not available for that date...",
          howMany : "How many people are coming?",
          bookingDate     : "Booking date",
          bookNow     : "Book Now!",
          price  : "Price",
          places  : "places left...",
        },
    
        Español: {
          location : "Ubicación",
          status : "Estado",
          available : "Disponible",
          notAvailable : "No disponible para ésa fecha...",
          howMany : "Cuántas personas vienen?",
          bookingDate     : "Fecha de reserva",
          bookNow     : "Reserva ahora!",
          price  : "Precio",
          places  : "lugares libres...",
        }
    
    }; 
    
    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);

    const [selectedDay, setSelectedDay] = useState(new Date());
    const [qty, setQty] = useState(1);
    const tipDetails = useSelector(state => state.tipDetails);
    const {tip, loading, error} = tipDetails;
    const attractionDetails = useSelector(state => state.attractionDetails);
    const {attraction, loading: loadingAttraction, error: errorAttraction} = attractionDetails;
    const dispatch = useDispatch();
    const parse = require('html-react-parser');

    useEffect(() => {
        dispatch(detailsTip(props.match.params.tip));
        dispatch(detailsAttraction(props.match.params.attraction));

        return () => {

        };
    }, []);

    const handleAddToCart = () =>{
        if (DateUtils.isDate(selectedDay) && DateUtils.isFutureDay(selectedDay)) {
            props.history.push("/cart/" + props.match.params.attraction + "?qty=" + qty + "=" + `${dateFnsFormat(selectedDay, FORMAT)}`)
        } else {
            alert("Please, select a valid booking date!");
        }
    };

    function addDays(date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    }
    
    const date = new Date();
    const newDate = addDays(date, 2);
    const FORMAT = 'dd/MM/yyyy';

    function parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
      }
      
    function formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
      }
    
    const article = loading? <div>&nbsp;</div>:
                    error? <div>{error}</div>:
                    !tip? <div>Tip not found...</div>:
                    props.language === "en"?
                    parse(String(tip.article)):
                    parse(String(tip.articleEs));

    const articlePic = loading? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    error? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    !tip? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    props.language === "en"?
                    <img className="responsive" src={ IMAGES_URL + tip.image} alt={tip.imageAlt}></img>:
                    <img className="responsive" src={ IMAGES_URL + tip.image} alt={tip.imageAltEs}></img>;

    const metas = loading? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    error? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    !tip? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    <MetaTags>
                    <title>{ tip.metaName }</title>
                    <meta name="description" content={ tip.description } />
                    <meta property="og:site_name" content={SITE_NAME} />;
                    <meta property="og:type" content="website" />;
                    <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                    <meta property="og:title" content={ tip.metaName } />
                    <meta property="og:description" content={ tip.description } />;
                    </MetaTags>    

    ReactGA.initialize(GA_ID);

    return <div className="wrapper">

        { metas }
        <div className="article">
            { articlePic }
            { article }
        </div>
        { loadingAttraction? <div>&nbsp;</div>:
            errorAttraction? <div>{error}</div>:
            (
                <div className="details">
                <div className="details-action">
                    <ul>
                        <li key="name" value={attraction.name}>
                            {
                                props.language === "en"?
                                <h2>{attraction.metaName}</h2>:
                                <h2>{attraction.metaNameEs}</h2>
                            }
                        </li>
                        <li key="finalPrice" value={attraction.price}>
                            <h3>{content.price}: ${attraction.price} USD</h3>
                        </li>
                        <li key="status" value={attraction.status}>
                            {content.status}: {attraction.maxUsersDay > 0 ? content.available: content.notAvailable}
                        </li>
                        <li key="stock" value={attraction.maxUsersDay}>
                            <p>{content.howMany} ({attraction.maxUsersDay} {content.places})</p>
                            <select value={qty} onChange={(e) => { setQty(e.target.value) }}>
                                {[...Array(10).keys()].map(x =>
                                    <option key={x + 1} value={x + 1}>{x + 1}</option>
                                )}
                            </select>
    
                            <p>{content.bookingDate}:</p>
                            <DayPicker
                                formatDate={formatDate}
                                format={FORMAT}
                                parseDate={parseDate}
                                showOutsideDays
                                disabledDays={[
                                    {
                                        before: newDate,
                                    },
                                ]}
                                selectedDays={selectedDay}
                                onDayClick={(day) => setSelectedDay(day)}
                            />
    
                        </li>
    
                        <li key="booking" value="call_to_action">
                            {
                                attraction.maxUsersDay > 0 && <div className="callToAction"><button onClick={handleAddToCart}>{content.bookNow}</button></div>
                            }
                        </li>
    
                    </ul>
                </div>
            </div>
            )
        }

    </div>
}

export default TipScreen;