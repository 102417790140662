import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_BOOKING, CART_SAVE_PAYMENT, CART_EMPTY } from "../constants/cartConstants";

function cartReducer(state = { cartItems: [] }, action){
    switch(action.type){
        case CART_ADD_ITEM:
            const item = action.payload;
            const attraction = state.cartItems.find(x=> x.attraction === item.attraction);
            if (attraction){
                return {
                    cartItems: 
                        state.cartItems.map(x=>x.attraction===attraction.attraction? item: x)
                    };
            }
            return {cartItems: [...state.cartItems, item] };
        case CART_REMOVE_ITEM:
            return { cartItems: state.cartItems.filter(x => x.attraction !== action.payload) };
        case CART_SAVE_BOOKING:
            return { ...state, booking: action.payload };
        case CART_SAVE_PAYMENT:
            return { ...state, payment: action.payload };
        case CART_EMPTY:
            return {};
        default: 
            return state;
    }
}

export { cartReducer }