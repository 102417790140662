import React, { useEffect } from 'react'
import { addToCart, removeFromCart } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function CartScreen(props) {

    let content = {

        English: {
            title : "Booking cart in Comparte Stereo",
            description : "List of ATTRACTIONS for booking.",
            bookingCart : "Booking Cart",
            price : "Price",
            empty : "Cart is empty",
            people     : "People",
            date     : "Booking date:",
            delete     : "Delete",
            proceed     : "Proceed to Checkout",
        },
    
        Español: {
            title : "Lista de reservas en Comparte Stereo",
            description : "Lista de attracciones para reservar.",
            bookingCart : "Lista de reservas",
            price : "Precio",
            empty : "Lista vacía",
            people     : "Personas",
            date     : "Fecha de la reserva:",
            delete     : "Borrar",
            proceed     : "Proceder al Checkout",
        }
    
    }; 
    
    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);    

    const cart = useSelector(state => state.cart);

    const { cartItems } = cart;

    const attractionId = props.match.params.id;
    const qty = props.location.search ? Number(props.location.search.split("=")[1]) : 1;
    const date = props.location.search ? String(props.location.search.split("=")[2]) : 1;
    const dispatch = useDispatch();

    const removeFromCartHandler = (attractionId) => {
        dispatch(removeFromCart(attractionId));
    }

    useEffect(() =>{
        if(attractionId){
            dispatch(addToCart(attractionId, qty, date));
        }
    }, [])

    const checkoutHandler = () =>{
        props.history.push('/' + props.language + '/signin?redirect=/' + props.language + '/booking');
    }

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>{content.title}</title>
                <meta name="description" content={content.description} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content={content.title} />
                <meta property="og:description" content={content.description} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="cart">
                <div className="cart-list">
                    <ul className="cart-list-container">
                        <li>
                            <h3>
                                {content.bookingCart}
                            </h3>
                            <div>
                                {content.price}
                            </div>
                        </li>
                        {
                            cartItems.length === 0 ?
                            <div>
                                {content.empty}
                            </div>
                            :
                            cartItems.map( item =>
                            <li key={item.name} value={item.name}>
                                <div className="cart-image">
                                    <img src={ IMAGES_URL + item.image} alt="attraction" />
                                </div> 

                                <div className="cart-name">
                                    <div>
                                        <Link to={'/' + props.language + item.path}>
                                            {item.name}
                                        </Link>
                                    </div>
                                    <div>
                                        {content.people}:
                                        <select value={item.qty} onChange={(e) => dispatch(addToCart(item.attraction, parseInt(e.target.value), date))}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                        {content.date}
                                        { item.date }
                                        <button type="button" className="button" onClick={() => removeFromCartHandler(item.attraction)}>
                                            {content.delete}
                                        </button>
                                    </div>
                                </div>
                                <div className="cart-price">
                                    ${item.price}
                                </div>
                            </li>   
                            )
                        }

                    </ul>
                </div>

                <div className="cart-action">
                    <h3>
                        Subtotal ( {cartItems.reduce((a, c) => a + c.qty, 0)} {content.people})
                        :
                        $ {cartItems.reduce((a, c) => a + c.price * c.qty, 0)}
                    </h3>
                    <button onClick={checkoutHandler} className="button primary full-width" disabled={cartItems.length === 0}>
                        {content.proceed}
                    </button>
                </div>
            </div>
        </div>
    )

}

export default CartScreen;