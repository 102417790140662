import React from 'react';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import logo from '../resources/logo192.png';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function ReturnPolicyScreen({ props }) {

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>Return Policy</title>
                <meta name="description" content="Return Policy of Comparte Stereo" />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="Return Policy" />
                <meta property="og:description" content="Return Policy of Comparte Stereo" />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div  className="article">
                <h1>RETURN POLICY -- Comparte Stereo</h1>
                <p>Last updated: January 06, 2021</p>
                <p>Thank you for your booking. We hope you are happy with your booking. However, if you are not completely satisfied with your booking for any reason, you may cancel it for a full refund. Please see below for more information on our return policy.</p>
                <h2>CANCELLATIONS</h2>
                <p>All booking cancellations must be before 2 days to the activity date or it would be placed automatically if the booked activity can not be done because of safety reasons or the activity operator has a last-minute major problem and the service is not available.</p>
                <h2>CANCELLATION PROCESS</h2>
                <p>To cancel a booking, please email customer service at travel@attractions.tips to obtain a cancellation and a refund.</p>
                <h2>REFUNDS</h2>
                <p>After receiving your request, please allow at least 2 days to process your booking cancellation.  Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.  We will notify you by email when your cancellation has been processed.</p>
                <h2>QUESTIONS</h2>
                <p>If you have any questions concerning our return policy, please contact us at:</p>
                <p>compartestereo@gmail.com</p>
                </div>
        </div>
    )

}
export default ReturnPolicyScreen;