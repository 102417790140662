export const ATTRACTION_LIST_REQUEST = 'ATTRACTION_LIST_REQUEST';
export const ATTRACTION_LIST_SUCCESS = 'ATTRACTION_LIST_SUCCESS';
export const ATTRACTION_LIST_FAIL = 'ATTRACTION_LIST_FAIL';

export const ATTRACTION_DETAILS_REQUEST = 'ATTRACTION_DETAILS_REQUEST';
export const ATTRACTION_DETAILS_SUCCESS = 'ATTRACTION_DETAILS_SUCCESS';
export const ATTRACTION_DETAILS_FAIL = 'ATTRACTION_DETAILS_FAIL';

export const ATTRACTION_SAVE_REQUEST = 'ATTRACTION_SAVE_REQUEST';
export const ATTRACTION_SAVE_SUCCESS = 'ATTRACTION_SAVE_SUCCESS';
export const ATTRACTION_SAVE_FAIL = 'ATTRACTION_SAVE_FAIL';

export const ATTRACTION_DELETE_REQUEST = 'ATTRACTION_DELETE_REQUEST';
export const ATTRACTION_DELETE_SUCCESS = 'ATTRACTION_DELETE_SUCCESS';
export const ATTRACTION_DELETE_FAIL = 'ATTRACTION_DELETE_FAIL';

export const ATTRACTION_FILTER_REQUEST = 'ATTRACTION_FILTER_REQUEST';
export const ATTRACTION_FILTER_SUCCESS = 'ATTRACTION_FILTER_SUCCESS';
export const ATTRACTION_FILTER_FAIL = 'ATTRACTION_FILTER_FAIL';