import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { listOrders, deleteOrder } from '../actions/orderActions';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';

function OrdersScreen(props) {

  const orderList = useSelector(state => state.orderList);
  const { loading, orders, error } = orderList;

  const orderDelete = useSelector(state => state.orderDelete);
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = orderDelete;

  const dispatch = useDispatch();

  const calcDate = (unixTime) => {
    var numberUnixTime = parseInt(unixTime);
    var dateResult = new Date(numberUnixTime);
    var dateText = dateResult.toLocaleString();
    return dateText;
  }

  useEffect(() => {
    dispatch(listOrders());
    return () => {
      //
    };
  }, [successDelete]);

  const deleteHandler = (order) => {
    dispatch(deleteOrder(order.idString));
  }

  ReactGA.initialize(GA_ID);

  return loading ? <div>Loading...</div> :
            error ? <div>{error} </div> :
            orders != 'No orders' ?
            (
              <div className="content content-margined">

                <div className="order-header">
                  <h3>Orders</h3>
                </div>
                <div className="order-list">

                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>DATE</th>
                        <th>TOTAL</th>
                        <th>USER</th>
                        <th>PAID</th>
                        <th>PAID AT</th>
                        <th>DELIVERED</th>
                        <th>DELIVERED AT</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => (<tr key={order.idString}>
                        <td>{order.idString}</td>
                        <td>{ calcDate(order.idString) }</td>
                        <td>{order.totalPrice}</td>
                        <td>{order.owner}</td>
                        <td>{order.isPaid.toString()}</td>
                        <td>{order.paidAt}</td>
                        <td>{order.isDelivered.toString()}</td>
                        <td>{order.deliveredAt}</td>
                        <td>
                          <Link to={"/order/" + order.idString} className="button secondary" >Details</Link>
                          {' '}
                          <button type="button" onClick={() => deleteHandler(order)} className="button secondary">Delete</button>
                        </td>
                      </tr>))}
                    </tbody>
                  </table>

                </div>
              </div>
            ) :
            <div>No Orders</div>
}
export default OrdersScreen;