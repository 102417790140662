import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Mail from '@material-ui/icons/Mail';
import PostAdd from '@material-ui/icons/PostAdd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Cancel from '@material-ui/icons/Cancel';
import enFlag from '../resources/gb.png';

function SideDrawer(props) {

    let content = {

        English: {
            signIn : "Sign In",
            contactUs : "Contact us!",
            bookingCart : "Booking Cart",
            myReviews : "My Reviews",
        },
    
        Español: {
            signIn : "Entrar",
            contactUs : "Contáctanos!",
            bookingCart : "Reservas",
            myReviews : "Mis Reviews",
        }
    
    }; 

    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);

    const userSignin = useSelector(state=>state.userSignin);
    const {userInfo} = userSignin;

    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={()=> props.onClose(false)}            
        >

            <List component="nav" className="side-drawer">
                <ListItem button onClick={()=> props.onClose(false)}>
                    <>-----------</>
                    <Cancel fontSize="large"/>
                    <>-----------</>
                </ListItem>
                <ListItem button>
                    <AccountCircle fontSize="large"/>
                    {
                        userInfo ? <Link to={'/profile'} onClick={()=>props.onClose(false)}>&nbsp;&nbsp;{userInfo.name}</Link>
                        :
                        <Link to={'/signin'} onClick={()=>props.onClose(false)}>&nbsp;&nbsp;{content.signIn}</Link>
                    }
                </ListItem>
                <ListItem button>
                    <Mail fontSize="large"/>
                    <Link to={'/contact'} onClick={()=>props.onClose(false)}>&nbsp;&nbsp;{content.contactUs}</Link>
                </ListItem>
                <ListItem button>
                    <ShoppingCart fontSize="large"/>
                    <Link to={'/cart'} onClick={()=>props.onClose(false)}>&nbsp;&nbsp;{content.bookingCart}</Link>
                </ListItem>
                <ListItem button>
                    {
                        userInfo ? <PostAdd fontSize="large"/>
                        :
                        <p>&nbsp;</p>
                    }
                    {
                        userInfo ? <Link to={'/create-review'} onClick={()=>props.onClose(false)}>&nbsp;&nbsp;{content.myReviews}</Link>
                        :
                        <p>&nbsp;</p>
                    }
                </ListItem>
            </List>


        </Drawer>
    );
};

export default SideDrawer;