import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { register, logout } from '../actions/userActions';
import { RECAPTCHA_KEY } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function RegisterScreen(props) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const userRegister = useSelector(state => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  const dispatch = useDispatch();

  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';
  useEffect(() => {
    if (userInfo) {
      dispatch(logout());
      props.history.push(redirect);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (name && email) {
      if (password == rePassword) {
        if (isVerified) {
          dispatch(register(name, email, password));
          alert("An e-mail has been sent with a link to confirm your account.  Just log in with the password provided.")
        }else {
          alert("Please verify you are a human!");
        }
      } else {
        alert("Check the password!")
      }
    } else {
      alert("Please fill out your information!")
    }

  }

  function onChange(value) {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }

  ReactGA.initialize(GA_ID);

  return (
    <div className="wrapper">
            <MetaTags>
                <title>Join ATTRACTIONS TIPS.  Create your account.</title>
                <meta name="description" content="Become an ATTRACTIONS TIPS guest or host." />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="Join ATTRACTIONS TIPS.  Create your account." />
                <meta property="og:description" content="Become an ATTRACTIONS TIPS guest or host." />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="form">
              <form onSubmit={submitHandler} >
                <ul className="form-container">
                  <li>
                    <h2>Crear una cuenta</h2>
                  </li>
                  <li>
                    {loading && <div>Enviando email...</div>}
                    {error && <div>{error}</div>}
                  </li>
                  <li>
                    <label htmlFor="name">
                      Nombre
                    </label>
                    <input type="name" name="name" id="name" onChange={(e) => setName(e.target.value)}>
                    </input>
                  </li>
                  <li>
                    <label htmlFor="email">
                      Email
                    </label>
                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
                    </input>
                  </li>
                  <li>
                    <label htmlFor="password">Contraseña</label>
                    <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}>
                    </input>
                  </li>
                  <li>
                    <label htmlFor="rePassword">De nuevo, la contraseña</label>
                    <input type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)}>
                    </input>
                  </li>
                  <li>
                    Recibirás un email con un link para confirmar tu cuenta...
                    <button type="submit" className="button primary">Registrarse</button>
                  </li>
                  <li>
                    Ya tienes una cuenta?
                    <Link to={redirect === "/" ? "signin" : "signin?redirect=" + redirect} className="button secondary text-center" >Ingresar</Link>

                  </li>
                  <li>
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_KEY}
                      onChange={onChange}
                    />
                  </li>
                </ul>
              </form>
            </div>
    </div>
  )
  
}
export default RegisterScreen;