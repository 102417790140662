import { 
    TIP_LIST_REQUEST, 
    TIP_LIST_SUCCESS, 
    TIP_LIST_FAIL, 
    TIP_DETAILS_REQUEST, 
    TIP_DETAILS_SUCCESS, 
    TIP_DETAILS_FAIL,
    TIP_SAVE_REQUEST,
    TIP_SAVE_SUCCESS,
    TIP_SAVE_FAIL,
    TIP_DELETE_REQUEST,
    TIP_DELETE_SUCCESS,
    TIP_DELETE_FAIL,
    TIP_FILTER_REQUEST,
    TIP_FILTER_SUCCESS,
    TIP_FILTER_FAIL,
    SHORT_TIP_LIST_REQUEST, 
    SHORT_TIP_LIST_SUCCESS, 
    SHORT_TIP_LIST_FAIL, 
    SHORT_TIP_DETAILS_REQUEST, 
    SHORT_TIP_DETAILS_SUCCESS, 
    SHORT_TIP_DETAILS_FAIL,
    SHORT_TIP_SAVE_REQUEST,
    SHORT_TIP_SAVE_SUCCESS,
    SHORT_TIP_SAVE_FAIL,
    SHORT_TIP_DELETE_REQUEST,
    SHORT_TIP_DELETE_SUCCESS,
    SHORT_TIP_DELETE_FAIL,
    SHORT_TIP_FILTER_REQUEST,
    SHORT_TIP_FILTER_SUCCESS,
    SHORT_TIP_FILTER_FAIL,
    SHORT_TIP_AUTHOR_FILTER_REQUEST,
    SHORT_TIP_AUTHOR_FILTER_SUCCESS,
    SHORT_TIP_AUTHOR_FILTER_FAIL,
  } from "../constants/tipConstants";
  
  function tipListReducer(state= {tips:[]}, action){
  
      switch (action.type) {
          case    TIP_LIST_REQUEST:
              return  {loading: true};
          case    TIP_LIST_SUCCESS:
              return  {loading: false, tips: action.payload}
          case    TIP_LIST_FAIL:
              return  {loading: false, error: action.payload}
          default:
              return  state;
      }
  }
  
  function tipFilterReducer(state= {tips:[]}, action){

    switch (action.type) {
        case    TIP_FILTER_REQUEST:
            return  {loading: true};
        case    TIP_FILTER_SUCCESS:
            return  {loading: false, tips: action.payload}
        case    TIP_FILTER_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
  }
  
  function tipDetailsReducer(state = { tip: {} }, action) {
  
      switch (action.type) {
        case TIP_DETAILS_REQUEST:
          return { loading: true };
        case TIP_DETAILS_SUCCESS:
          return { loading: false, tip: action.payload };
        case TIP_DETAILS_FAIL:
          return { loading: false, error: action.payload }
        default:
          return state;
      }
    }
  
  function tipDeleteReducer(state = { tip: {} }, action) {
    switch (action.type) {
      case TIP_DELETE_REQUEST:
        return { loading: true };
      case TIP_DELETE_SUCCESS:
        return { loading: false, tip: action.payload, success: true };
      case TIP_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  
  function tipSaveReducer(state = { tip: {} }, action) {
    switch (action.type) {
      case TIP_SAVE_REQUEST:
        return { loading: true };
      case TIP_SAVE_SUCCESS:
        return { loading: false, success: true, tip: action.payload };
      case TIP_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  function shortTipListReducer(state= {tips:[]}, action){
  
    switch (action.type) {
        case    SHORT_TIP_LIST_REQUEST:
            return  {loading: true};
        case    SHORT_TIP_LIST_SUCCESS:
            return  {loading: false, tips: action.payload}
        case    SHORT_TIP_LIST_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
  }

  function shortTipFilterReducer(state= {tips:[]}, action){

    switch (action.type) {
        case    SHORT_TIP_FILTER_REQUEST:
            return  {loading: true};
        case    SHORT_TIP_FILTER_SUCCESS:
            return  {loading: false, tips: action.payload}
        case    SHORT_TIP_FILTER_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
  }

  function shortTipAuthorFilterReducer(state= {tips:[]}, action){

    switch (action.type) {
        case    SHORT_TIP_AUTHOR_FILTER_REQUEST:
            return  {loading: true};
        case    SHORT_TIP_AUTHOR_FILTER_SUCCESS:
            return  {loading: false, tips: action.payload}
        case    SHORT_TIP_AUTHOR_FILTER_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
  }

  function shortTipDetailsReducer(state = { shortTip: {} }, action) {

      switch (action.type) {
        case SHORT_TIP_DETAILS_REQUEST:
          return { loading: true };
        case SHORT_TIP_DETAILS_SUCCESS:
          return { loading: false, shortTip: action.payload };
        case SHORT_TIP_DETAILS_FAIL:
          return { loading: false, error: action.payload }
        default:
          return state;
      }
    }

  function shortTipDeleteReducer(state = { tip: {} }, action) {
    switch (action.type) {
      case SHORT_TIP_DELETE_REQUEST:
        return { loading: true };
      case SHORT_TIP_DELETE_SUCCESS:
        return { loading: false, tip: action.payload, success: true };
      case SHORT_TIP_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  function shortTipSaveReducer(state = { tip: {} }, action) {
    switch (action.type) {
      case SHORT_TIP_SAVE_REQUEST:
        return { loading: true };
      case SHORT_TIP_SAVE_SUCCESS:
        return { loading: false, success: true, tip: action.payload };
      case SHORT_TIP_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export {tipListReducer, tipDetailsReducer, tipDeleteReducer, tipSaveReducer, tipFilterReducer, shortTipListReducer, shortTipDetailsReducer, shortTipDeleteReducer, shortTipSaveReducer, shortTipFilterReducer, shortTipAuthorFilterReducer}