import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  saveLocation,
  listLocations,
  deleteLocation,
} from '../actions/locationActions';
import {
  listCountries,
} from '../actions/countryActions';
import {
  listUsers,
} from '../actions/userActions';
import { BACKEND_URL } from "../constants/generalConstants"
import ReactGA from 'react-ga4';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IMAGES_URL } from '../constants/generalConstants';
import { GA_ID } from '../constants/generalConstants';

function LocationsScreen(props) {

  let content = {

    English: {
      locations : "Locations",
      createLocation : "Create Location",
      country : "Country",
      edit : "Edit",
      name : "Name",
      owner : "Owner",
      chooseUser     : "Choose an User",
      title     : "Title",
      description     : "Description",
      image     : "Image",
      imageAlt     : "Image Alt",
      article     : "Article",
      create     : "Create",
      update     : "Update",
      back     : "Back",
      delete     : "Delete",
    },

    Español: {
      locations : "Locaciones",
      createLocation : "Crear Locación",
      country : "País",
      edit : "Editar",
      name : "Nombre",
      owner : "Propietario",
      chooseUser     : "Escoge un ususario",
      title     : "Título",
      description     : "Descripción",
      image     : "Imagen",
      imageAlt     : "Imagen Alt",
      article     : "Artículo",
      create     : "Crear",
      update     : "Actualizar",
      back     : "Atrás",
      delete     : "Borrar",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [modalVisible, setModalVisible] = useState(false);
  const [parent, setParent] = useState('');
  const [name, setName] = useState('');
  const [metaName, setMetaName] = useState('');
  const [metaNameEs, setMetaNameEs] = useState('');
  const [image, setImage] = useState('');
  const [imageAlt, setImageAlt] = useState('');
  const [imageAltEs, setImageAltEs] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEs, setDescriptionEs] = useState('');
  const [article, setArticle] = useState('');
  const [articleEs, setArticleEs] = useState('');
  const [owner, setOwner] = useState('');
  const [uploading, setUploading] = useState(false);
  const locationList = useSelector((state) => state.locationList);
  const { loading, locations, error } = locationList;
  const countryList = useSelector((state) => state.countryList);
  const { loadingCountries, countries, errorCountries } = countryList;
  const userList = useSelector((state) => state.userList);
  const { loadingUsers, users, errorUsers } = userList;

  const locationSave = useSelector((state) => state.locationSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = locationSave;

  const locationDelete = useSelector((state) => state.locationDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = locationDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    dispatch(listLocations());
    dispatch(listCountries());
    dispatch(listUsers());
    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (location) => {
    setModalVisible(true);
    setParent(location.parent);
    setName(location.name);
    setMetaName(location.metaName);
    setMetaNameEs(location.metaNameEs);
    setImage(location.image);
    setImageAlt(location.imageAlt);
    setImageAltEs(location.imageAltEs);
    setDescription(location.description);
    setDescriptionEs(location.descriptionEs);
    setArticle(location.article);
    setArticleEs(location.articleEs);
    setOwner(location.owner);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    var id = name.replace(/\s/g, '-') ;
    var result = id.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'') ;
    id = result.toLowerCase();
    id = id.replace('ñ', 'n') ;

    dispatch(
      saveLocation({
        id,
        parent,
        name,
        metaName,
        metaNameEs,
        image,
        imageAlt,
        imageAltEs,
        description,
        descriptionEs,
        article,
        articleEs,
        owner,
      })
    );
  };

  const deleteHandler = (location) => {
    dispatch(deleteLocation(location.id));
  };

  function refreshImage(imgElement, imgURL){    
    var timestamp = new Date().getTime();    
    var el = document.getElementById(imgElement);  
    var queryString = "?t=" + timestamp;
    el.src = IMAGES_URL + imgURL + queryString;    
  }  

  const rotateCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/cw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const rotateCCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/ccw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post( BACKEND_URL + '/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const editorConfiguration = {
    heading: {
      options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      ]
    },

  };

  ReactGA.initialize(GA_ID);

  return (
    <div className="content content-margined">
      <div className="product-header">
        <h3>{content.locations}</h3>
        <button className="button primary" onClick={() => openModal({})}>
          {content.createLocation}
        </button>
      </div>
      {modalVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>{content.createLocation}</h2>
              </li>
              <li>
                {loadingSave && <div>Loading...</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>

              <li>
                <label htmlFor="owner">{content.owner}</label>
                {users!=null? 
                (
                  <select value={owner} onChange={(e) => setOwner(e.target.value)}>
                      <option value="">{content.chooseUser}</option>
                      {users.map((user) => (
                          <option key={user.email} value={user.email}>{user.email}</option>
                      ))}
                  </select>
                ) :
                <div>No Users</div>}
              </li>
              <li>
                <label htmlFor="country">{content.country}</label>
                {countries!=null? 
                (
                  <select value={parent} onChange={(e) => setParent(e.target.value)}>
                      <option value="">Choose a Country</option>
                      {countries.map((country) => (
                          <option key={country.id} value={country.id}>{country.name}</option>
                      ))}
                  </select>
                ) :
                <div>No Countries</div>}
              </li>
              <li>
                <label htmlFor="name">{content.name}</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </li>
              {
                props.language === "en"
                ?  <li>
                      <label htmlFor="metaName">{content.title} ({props.language})</label>
                      <textarea
                        rows="5"
                        name="metaName"
                        value={metaName}
                        id="metaName"
                        onChange={(e) => setMetaName(e.target.value)}
                      ></textarea>
                    </li>
                :  <li>
                      <label htmlFor="metaNameEs">{content.title} ({props.language})</label>
                      <textarea
                        rows="5"
                        name="metaNameEs"
                        value={metaNameEs}
                        id="metaNameEs"
                        onChange={(e) => setMetaNameEs(e.target.value)}
                      ></textarea>
                    </li>
              }

              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="description">{content.description} ({props.language})</label>
                    <textarea
                      rows="8"
                      name="description"
                      value={description}
                      id="description"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </li>
                :  <li>
                    <label htmlFor="descriptionEs">{content.description} ({props.language})</label>
                    <textarea
                      rows="8"
                      name="descriptionEs"
                      value={descriptionEs}
                      id="descriptionEs"
                      onChange={(e) => setDescriptionEs(e.target.value)}
                    ></textarea>
                  </li>
              }
              <li>
                <div className="showImage">
                {!image ? <b>&nbsp;</b> : 
                    <div>
                      <img id="articlePic" src={ IMAGES_URL + image} alt="Location picture" className="showImage"/>
                      <b onClick={() => { rotateCCWHandler(image) }}>Left</b>
                      <b> -Rotate- </b>
                      <b onClick={() => { rotateCWHandler(image) }}>Right</b>
                    </div>
                }
                </div> 
              </li>
              <li>
                <label htmlFor="image">{content.image}</label>
                <input
                  type="text"
                  name="image"
                  value={image}
                  id="image"
                  onChange={(e) => setImage(e.target.value)}
                ></input>
                <input type="file" onChange={uploadFileHandler}></input>
                {uploading && <h2>Uploading...</h2>}
              </li>

              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="imageAlt">{content.imageAlt} ({props.language})</label>
                    <input
                      type="text"
                      name="imageAlt"
                      value={imageAlt}
                      id="imageAlt"
                      onChange={(e) => setImageAlt(e.target.value)}
                    ></input>
                  </li>
                :  <li>
                    <label htmlFor="imageAltEs">{content.imageAlt} ({props.language})</label>
                    <input
                      type="text"
                      name="imageAltEs"
                      value={imageAltEs}
                      id="imageAltEs"
                      onChange={(e) => setImageAltEs(e.target.value)}
                    ></input>
                  </li>
              }

              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="article">{content.article} ({props.language})</label>
                    <CKEditor 
                        editor={ ClassicEditor }
                        config={editorConfiguration}
                        data={article}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setArticle(data);
                        } }
                    />
                  </li>
                :  <li>
                    <label htmlFor="articleEs">{content.article} ({props.language})</label>
                    <CKEditor 
                        editor={ ClassicEditor }
                        config={editorConfiguration}
                        data={articleEs}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setArticleEs(data);
                        } }
                    />
                  </li>
                }

              <li>
                <button type="submit" className="button primary">
                  {name ? 'Update' : 'Create'}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="button secondary"
                >
                  {content.back}
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}

      { loading? <div>Loading...</div>:
            error? <div>{error}</div>:
            locations!=null? 
            (
              <div className="product-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{content.name}</th>
                      <th>{content.country}</th>
                      <th>{content.description}</th>
                      <th>{content.owner}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location) => (
                      <tr key={location.id}>
                        <td><a href={location.parent + "/" + location.id}> {location.name}</a></td>
                        <td>{location.parent}</td>
                        {
                          props.language === "en"
                          ? <td>{location.description}</td>
                          : <td>{location.descriptionEs}</td>
                        }
                        <td>{location.owner}</td>
                        <td>
                          <button className="button" onClick={() => openModal(location)}>
                            {content.edit}
                          </button>{' '}
                          <button
                            className="button"
                            onClick={() => { if (window.confirm("Are you sure to delete " + location.name + "?"))deleteHandler(location)}}
                          >
                            {content.delete}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) :
            <div>No Locations</div>
      }
      </div>

  );
}

export default LocationsScreen;