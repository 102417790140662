import { LOCATION_LIST_REQUEST, 
  LOCATION_LIST_SUCCESS, 
  LOCATION_LIST_FAIL,
  LOCATION_DETAILS_REQUEST, 
  LOCATION_DETAILS_SUCCESS, 
  LOCATION_DETAILS_FAIL,  
  LOCATION_DELETE_REQUEST, 
  LOCATION_DELETE_SUCCESS, 
  LOCATION_DELETE_FAIL, 
  LOCATION_SAVE_REQUEST, 
  LOCATION_SAVE_SUCCESS, 
  LOCATION_SAVE_FAIL,
  LOCATION_FILTER_REQUEST, 
  LOCATION_FILTER_SUCCESS, 
  LOCATION_FILTER_FAIL,
} from "../constants/locationConstants";

function locationListReducer(state= {locations:[]}, action){

    switch (action.type) {
        case    LOCATION_LIST_REQUEST:
            return  {loading: true};
        case    LOCATION_LIST_SUCCESS:
            return  {loading: false, locations: action.payload}
        case    LOCATION_LIST_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
}

function locationDetailsReducer(state = { location: {} }, action) {

  switch (action.type) {
    case LOCATION_DETAILS_REQUEST:
      return { loading: true };
    case LOCATION_DETAILS_SUCCESS:
      return { loading: false, location: action.payload };
    case LOCATION_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state;
  }
}

function locationFilterReducer(state= {locations:[]}, action){

  switch (action.type) {
      case    LOCATION_FILTER_REQUEST:
          return  {loading: true};
      case    LOCATION_FILTER_SUCCESS:
          return  {loading: false, locations: action.payload}
      case    LOCATION_FILTER_FAIL:
          return  {loading: false, error: action.payload}
      default:
          return  state;
  }
}

function locationDeleteReducer(state = { location: {} }, action) {
    switch (action.type) {
      case LOCATION_DELETE_REQUEST:
        return { loading: true };
      case LOCATION_DELETE_SUCCESS:
        return { loading: false, location: action.payload, success: true };
      case LOCATION_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
}
  
function locationSaveReducer(state = { location: {} }, action) {
    switch (action.type) {
      case LOCATION_SAVE_REQUEST:
        return { loading: true };
      case LOCATION_SAVE_SUCCESS:
        return { loading: false, success: true, location: action.payload };
      case LOCATION_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
}

export {locationListReducer, locationDetailsReducer, locationDeleteReducer, locationSaveReducer, locationFilterReducer}