import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signin } from '../actions/userActions';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function SigninScreen(props) {

    let content = {

        English: {
            title : "Sign in to ATTRACTIONS TIPS",
            description : "Sign in to book ATTRACTIONS or post TIPS of places of interest.",
            signIn : "Sign-In",
            email : "Email",
            password     : "Password",
            forgotPassword     : "Forgot password?",
            signInButton : "Sign In",
            new : "New in Comparte Stereo?",
            create : "Create your Comparte Stereo account",

        },
    
        Español: {
            title : "Entrar en Comparte Stereo",
            description : "Regístrate para hacer reservas o unirte como artista o emprendedor.",
            signIn : "Entrar",
            email : "Email",
            password     : "Contraseña",
            forgotPassword     : "Olvidó la contraseña?",
            signInButton : "Entrar",
            new : "Nuevo en Comparte Stereo?",
            create : "Crea tu cuenta en Comparte Stereo",

        }
    
    }; 

    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(props.match.params.token);
    const userSignin = useSelector(state => state.userSignin);
    const { loading, userInfo, error } = userSignin;
    const dispatch = useDispatch();
    const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

    useEffect(() => {
        if (userInfo) {
        props.history.push(redirect);
        }
        return () => {
        //
        };
    }, [userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (email != '') {
            dispatch(signin(email, password));
        }
    }
 
    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            <MetaTags>
                <title>{content.title}</title>
                <meta name="description" content={content.description} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content={content.title} />
                <meta property="og:description" content={content.description} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="form">
                <form onSubmit={submitHandler} >
                <ul className="form-container">
                    <li>
                    <h2>{content.signIn}</h2>
                    </li>
                    <li>
                    {loading && <div>Loading...</div>}
                    {error && <div>User or password not valid...</div>}
                    </li>
                    <li>
                    <label htmlFor="email">
                        {content.email}
                    </label>
                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
                    </input>
                    </li>
                    <li>
                    <label htmlFor="password">{content.password}</label>
                    <input type="password" id="password" name="password" value={props.match.params.token} onChange={(e) => setPassword(e.target.value)}>
                    </input>
                    <Link to={'/remember'} >{content.forgotPassword}</Link>
                    </li>
                    <li>
                    <button type="submit" className="button primary">{content.signInButton}</button>
                    </li>
                    <li>
                        {content.new}
                    </li>
                    <li>
                    <Link to={redirect === "/" ? "register" : "register?redirect=" + redirect} className="button secondary text-center" >{content.create}</Link>
                    </li>
                </ul>
                </form>
            </div>
        </div>
    )
    
}

export default SigninScreen;