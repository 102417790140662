import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { remember } from '../actions/userActions';
import { RECAPTCHA_KEY } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';

function RememberScreen(props) {

  let content = {

    English: {
        title : "Forgotten password at ATTRACTIONS TIPS",
        description : "Receive an email with a temporary password to access the site.",
        buttonSend : "Send an email with a link to sign in",
        email : "Email",
        passwordUpdate     : "Password update",
    },

    Español: {
        title : "Contraseña olvidada de ATTRACTIONS TIPS",
        description : "Recibe un email con una contraseña temporal para acceder al sitio.",
        buttonSend : "Enviar un email con un link para ingresar",
        email : "Email",
        passwordUpdate     : "Actualizar Contraseña",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    if (email) {
      if (isVerified) {
        e.preventDefault();
        dispatch(remember(email));
        alert("Message sent...  Please check out your email to follow the sign-in link!");
        props.history.push("/");
      } else {
        alert("Please verify you are a human!");
      }
    } else {
      alert("Please, enter the Email!");
    }
  }

  function onChange(value) {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }

  ReactGA.initialize(GA_ID);

  return (
    <div className="wrapper">
            <MetaTags>
                <title>{content.title}</title>
                <meta name="description" content={content.description} />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="{content.title}" />
                <meta property="og:description" content={content.description} />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="form">
              <form onSubmit={submitHandler} >
                <ul className="form-container">
                  <li>
                    <h2>{content.passwordUpdate}</h2>
                  </li>
                  <li>
                    <label htmlFor="email">
                      {content.email}
                    </label>
                    <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
                    </input>
                  </li>
                  <li>
                    <button type="submit" className="button primary">{content.buttonSend}</button>
                  </li>

                </ul>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_KEY}
                  onChange={onChange}
                />
              </form>
            </div>
    </div>
  )
  
}
export default RememberScreen;