import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

function UserAvatar(props) {
    const userSignin = useSelector(state=>state.userSignin);
    const {userInfo} = userSignin;

    return (
        !userInfo ? <b>&nbsp;</b> :
        <IconButton>
            <Link to={'/' + props.language + '/profile'}>
                <AccountCircle fontSize="large" color="primary"/>
            </Link>

        </IconButton>
    )
}

export default UserAvatar;
