import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { filterAttractions } from '../actions/attractionActions';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import parse from 'html-react-parser';
import MetaTags from 'react-meta-tags';
import { detailsLocation } from '../actions/locationActions';
import logo from '../resources/logo192.png';
import { SITE_NAME, SITE_URL, META_TITLE, META_DESCRIPTION, GA_ID } from '../constants/generalConstants';

function ActivityScreen( props ) {

    const attractionFilter = useSelector(state => state.attractionFilter);
    const {attractions, loading, error} = attractionFilter;
    const locationDetails = useSelector(state => state.locationDetails);
    const {location, loadingLocation, errorLocation} = locationDetails;
    const dispatch = useDispatch();
    const parse = require('html-react-parser');

    useEffect(() => {
        if (props.match.params.lang != props.language) { props.handleSetLanguage(props.match.params.lang) }
        dispatch(detailsLocation(props.match.params.location));
        dispatch(filterAttractions(props.match.params.location));
        
        return () => {

        };
    }, [])

    function ScrollToTopOnMount() {
        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
      
        return null;
      }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const linkList = loading? <div>&nbsp;</div>:
                     error? <div>{error}</div>:
                     !attractions? <div>No attractions</div>:
                attractions.map((activity) => {
        return (
            <li key={activity.id} value={activity.id}>
                    <img className="product-image" src={ IMAGES_URL + activity.image} alt={props.language=="en"? activity.imageAlt : activity.imageAltEs}></img>
                    <h3 className="product-name">{props.language=="en"? activity.metaName : activity.metaNameEs}</h3>
                    <div className="product-short">{props.language=="en"? activity.description : activity.descriptionEs}</div>
                    <div>&nbsp;</div>
                    <div className="callToAction">
                        <p>${activity.price} USD</p>
                        <a href={'/' + props.language + `${activity.path}`}>
                            <h4>{props.language=="en"? "Info & Booking" : "Info & Reservas"}</h4>
                        </a>
                    </div>                    
            </li>
        );
    });

    const articlePic = loadingLocation? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        errorLocation? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        !location? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        props.language === "en"?
                        <img className="responsive" src={ IMAGES_URL + location.image} alt={location.imageAlt}></img>:
                        <img className="responsive" src={ IMAGES_URL + location.image} alt={location.imageAltEs}></img>;

    const article = loadingLocation? <div>&nbsp;</div>:
                    errorLocation? <div>{error}</div>:
                    !location? <div>&nbsp;</div>:
                    props.language === "en"?
                        parse(String(location.article)):
                        parse(String(location.articleEs));

    const metas = loadingLocation? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        errorLocation? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        !location? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        props.language === "en"?
                            <MetaTags>
                                <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                                    href={props.language=="en"?SITE_URL + "/es/" + location.parent + "/" + location.id :SITE_URL + "/en/" + location.parent + "/" + location.id} />
                                <link rel="alternate" hreflang="x-default"
                                    href={SITE_URL + "/en/" + location.parent + "/" + location.id} />
                                <title>{ location.metaName }</title>
                                <meta name="description" content={ location.description } />
                                <meta property="og:site_name" content={SITE_NAME} />;
                                <meta property="og:type" content="website" />;
                                <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                                <meta property="og:title" content={ location.metaName } />
                                <meta property="og:description" content={ location.description } />;
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                            </MetaTags>:
                            <MetaTags>
                                <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                                    href={props.language=="en"?SITE_URL + "/es/" + location.parent + "/" + location.id :SITE_URL + "/en/" + location.parent + "/" + location.id} />
                                <link rel="alternate" hreflang="x-default"
                                    href={SITE_URL + "/en/" + location.parent + "/" + location.id} />
                                <title>{ location.metaNameEs }</title>
                                <meta name="description" content={ location.descriptionEs } />
                                <meta property="og:site_name" content={SITE_NAME} />;
                                <meta property="og:type" content="website" />;
                                <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                                <meta property="og:title" content={ location.metaNameEs } />
                                <meta property="og:description" content={ location.descriptionEs } />;
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                            </MetaTags>

    ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            { metas }
            <ScrollToTopOnMount />
            <div className="article">
                { articlePic }
                { article }
            </div>
            <ul className="products"> {linkList} </ul>
        </div>
     );

};
export default ActivityScreen;