import React from 'react';
function CheckoutSteps(props) {
  let content = {

    English: {
      signin : "Signin",
      booking : "Booking",
      payment : "Payment",
      placeOrder : "Done",
    },

    Español: {
      signin : "Registro",
      booking : "Reservar",
      payment : "Pagar",
      placeOrder : "Finalizar",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  return <div className="checkout-steps">
    <div className={props.step1 ? 'active' : ''} >{content.signin}</div>
    <div className={props.step2 ? 'active' : ''} >{content.booking}</div>
    <div className={props.step3 ? 'active' : ''} >{content.payment}</div>
    <div className={props.step4 ? 'active' : ''} >{content.placeOrder}</div>
  </div>
}

export default CheckoutSteps;