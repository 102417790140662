import axios from "axios";
import Cookie from "js-cookie";
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_BOOKING, CART_SAVE_PAYMENT, CART_EMPTY } from "../constants/cartConstants";
import { BACKEND_URL } from "../constants/generalConstants"

const addToCart = (attractionId, qty, date) => async (dispatch, getState) =>{

    try {
        const { data } = await axios.get( BACKEND_URL + "/attraction/" + attractionId);
        dispatch({type: CART_ADD_ITEM, payload:{
            attraction:     data.id,
            name:           data.name,
            path:           data.path,
            image:          data.image,
            price:          data.price,
            countInStock:   data.countInStock,
            qty,
            date
        }});
        const {  cart: { cartItems } } = getState();
        Cookie.set("cartItems", JSON.stringify(cartItems));

    } catch (error) {

    }
}

const removeFromCart = (attractionId) => (dispatch, getState) =>{
    dispatch({type: CART_REMOVE_ITEM, payload: attractionId});

    const {  cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));
}

const saveBooking = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_BOOKING, payload: data });
  }

const savePayment = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_PAYMENT, payload: data });
}

const emptyCart = () => (dispatch) => {
    Cookie.remove("cartItems");
    dispatch({ type: CART_EMPTY })
  }

export { addToCart, removeFromCart, saveBooking, savePayment, emptyCart };