import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { detailsAttraction } from '../actions/attractionActions';
import { IMAGES_URL } from '../constants/generalConstants';
import { filterTips } from '../actions/tipActions';
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import DayPicker from 'react-day-picker';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import logo from '../resources/logo192.png';
import {
    filterShortTips,
  } from '../actions/tipActions';
import Flickity from 'react-flickity-component';
import { SITE_NAME, SITE_URL, META_TITLE, META_DESCRIPTION, GA_ID } from '../constants/generalConstants';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function AttractionScreen(props) {
    let content = {

        English: {
          location : "Location",
          status : "Status",
          available : "Available",
          notAvailable : "Not available for that date...",
          howMany : "How many people are coming?",
          bookingDate     : "Booking date",
          bookNow     : "Book Now!",
          guidedBy     : "Guided by",
          reviewsFrom     : "Reviews from guests and hosts",
          price  : "Price",
          places  : "places left...",
          tips  : "Other activities to do there",
        },
    
        Español: {
          location : "Ubicación",
          status : "Estado",
          available : "Disponible",
          notAvailable : "No disponible para ésa fecha...",
          howMany : "Cuántas personas vienen?",
          bookingDate     : "Fecha de reserva",
          bookNow     : "Reserva ahora!",
          guidedBy     : "Guiado por",
          reviewsFrom     : "Reviews de huespedes y anfitriones",
          price  : "Precio",
          places  : "lugares libres...",
          tips  : "Otras actividades para hacer allí",
        }
    
    }; 
    
    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);

    const [selectedDay, setSelectedDay] = useState(new Date());
    const [qty, setQty] = useState(1);
    const attractionDetails = useSelector(state => state.attractionDetails);
    const {attraction, loading, error} = attractionDetails;
    const tipFilter = useSelector(state => state.tipFilter);
    const {tips, loadingTips, errorTips} = tipFilter;
    const dispatch = useDispatch();
    const parse = require('html-react-parser');
    const shortTipFilter = useSelector((state) => state.shortTipFilter);
    const { loading: loadingShortTips, tips: shortTips, error: errorShortTips } = shortTipFilter;

    useEffect(() => {
        if (props.match.params.lang != props.language) { props.handleSetLanguage(props.match.params.lang) }
        dispatch(detailsAttraction(props.match.params.attraction));
        dispatch(filterTips(props.match.params.attraction));
        dispatch(filterShortTips(props.match.params.attraction));

        return () => {

        };
    }, []);

    const handleAddToCart = () =>{
        if (DateUtils.isDate(selectedDay) && DateUtils.isFutureDay(selectedDay)) {
            props.history.push("/" + props.language + "/cart/" + props.match.params.attraction + "?qty=" + qty + "=" + `${dateFnsFormat(selectedDay, FORMAT)}`)
        } else {
            alert("Please, select a valid booking date!");
        }
    };

    function ScrollToTopOnMount() {
        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
      
        return null;
      }

    const article = loading? <div>Loading attraction...</div>:
                    error? <div>{error}</div>:
                    !attraction? <div>Attraction not found...</div>:
                    props.language === "en"?
                    parse(String(attraction.article)):
                    parse(String(attraction.articleEs));

    const articlePic = loading? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    error? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    !attraction.image? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                    props.language === "en"?
                    <img className="responsive" src={ IMAGES_URL + attraction.image} alt={attraction.imageAlt}></img>:
                    <img className="responsive" src={ IMAGES_URL + attraction.image} alt={attraction.imageAltEs}></img>;

    const linkList = loading? <div>Loading...</div>:
                    error? <div>{error}</div>:
                    !tips? <div>No tips...</div>:
                    tips.map((tip) => {
                        return (
                            <li key={tip.id} value={tip.id}>
                                <div>
                                    <img className="product-image" src={ IMAGES_URL + tip.image} alt={tip.imageAlt}></img>
                                    <div className="product-name">
                                        <h3>{tip.metaName}</h3>
                                    </div>
                                    <div className="product-short">{tip.description}</div>
                                    <div>&nbsp;</div>
                                    <div>&nbsp;</div>
                                    <div className="callToAction">
                                        <a href={`${props.match.url}/${tip.id}`}>
                                            <h4>Read more</h4>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        );
                    });

    const mapStyles = {        
        height: "80vh",
        width: "100%"};
      
    const Map = !attraction? <div>&nbsp;</div> :
                        attraction.latPos?
                        <div>
                            <h2 className="article">{content.location}:</h2>
                            <LoadScript
                                googleMapsApiKey='AIzaSyB0mXibd3eF6k_K5jXopIBV9asO_bbgJUM'>
                                <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={13}
                                center={{ lat: parseFloat(attraction.latPos), lng: parseFloat(attraction.longPos) }}
                                >
                                    <Marker key={attraction.name} position={{ lat: parseFloat(attraction.latPos), lng: parseFloat(attraction.longPos) }}/>
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        :
                        <div>&nbsp;</div>

    function addDays(date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    }
    
    const date = new Date();
    const newDate = addDays(date, 2);
    const FORMAT = 'dd/MM/yyyy';

    function parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
      }
      
    function formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
      }
    
    const metas = loading? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    error? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    !attraction? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                    props.language === "en"?
                    <MetaTags>
                        <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                            href={props.language=="en"?SITE_URL + "/es" + attraction.path :SITE_URL + "/en" + attraction.path} />
                        <link rel="alternate" hreflang="x-default"
                            href={SITE_URL + "/en" + attraction.path} />
                        <title>{ attraction.metaName }</title>
                        <meta name="description" content={ attraction.description } />
                        <meta property="og:site_name" content={SITE_NAME} />;
                        <meta property="og:type" content="website" />;
                        <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                        <meta property="og:title" content={ attraction.metaName } />
                        <meta property="og:description" content={ attraction.description } />;
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                    </MetaTags>:
                    <MetaTags>
                        <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                            href={props.language=="en"?SITE_URL + "/es" + attraction.path :SITE_URL + "/en" + attraction.path} />
                        <link rel="alternate" hreflang="x-default"
                            href={SITE_URL + "/en" + attraction.path} />
                        <title>{ attraction.metaNameEs }</title>
                        <meta name="description" content={ attraction.descriptionEs } />
                        <meta property="og:site_name" content={SITE_NAME} />;
                        <meta property="og:type" content="website" />;
                        <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                        <meta property="og:title" content={ attraction.metaNameEs } />
                        <meta property="og:description" content={ attraction.descriptionEs } />;
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                    </MetaTags>   

    const flickityOptions = {
        initialIndex: 2,
        adaptiveHeight: true,
        wrapAround: true,
        pageDots: true,
        prevNextButtons: true,
        pageDots: false,
        autoPlay: 10000,
    }

    ReactGA.initialize(GA_ID);

    return <div className="wrapper">

        { metas }
        { loading? <div className="article"><img className="responsive" src={logo} alt={SITE_NAME}></img></div>:
            error? <div>{error}</div>:
            (
            <div>
                <div className="article">
                    { articlePic }
                    { article }
                </div>

                <div className="details">
                    <div className="details-action">
                        <ul>
                            <li key="name" value={attraction.name}>
                                {
                                    props.language === "en"?
                                    <h2>{attraction.metaName}</h2>:
                                    <h2>{attraction.metaNameEs}</h2>
                                }
                            </li>
                            <li key="finalPrice" value={attraction.price}>
                                <h3>{content.price}: ${attraction.price} USD</h3>
                            </li>
                            <li key="status" value={attraction.status}>
                                {content.status}: {attraction.maxUsersDay > 0 ? content.available: content.notAvailable}
                            </li>
                            <li key="stock" value={attraction.maxUsersDay}>
                                <p>{content.howMany} ({attraction.maxUsersDay} {content.places})</p>
                                <select value={qty} onChange={(e) => { setQty(e.target.value) }}>
                                    {[...Array(10).keys()].map(x =>
                                        <option key={x + 1} value={x + 1}>{x + 1}</option>
                                    )}
                                </select>

                                <p>{content.bookingDate}:</p>
                                <DayPicker
                                    formatDate={formatDate}
                                    format={FORMAT}
                                    parseDate={parseDate}
                                    showOutsideDays
                                    disabledDays={[
                                        {
                                            before: newDate,
                                        },
                                    ]}
                                    selectedDays={selectedDay}
                                    onDayClick={(day) => setSelectedDay(day)}
                                />

                            </li>

                            <li key="booking" value="call_to_action">
                                {
                                    attraction.maxUsersDay > 0 && <div className="callToAction"><button onClick={handleAddToCart}>{content.bookNow}</button></div>
                                }
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="article">
                    {
                    !attraction? <div>&nbsp;</div>:
                        <div>
                        <div className="player-wrapper">
                            <iframe className="react-player" width="560" height="315" src={attraction.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        </div>
                    }
                </div>
            </div>

            )
        }

        {shortTips!=null? 
            (
                <div className="article">
                    <h2>{content.reviewsFrom}</h2>
                    <Flickity
                        className={'carousel'} // default ''
                        elementType={'div'} // default 'div'
                        options={flickityOptions} // takes flickity options {}
                        disableImagesLoaded={false} // default false
                        reloadOnUpdate // default false
                        static // default false
                    >
                        {shortTips.map((shortTip) => (
                            <div className="carousel" key={ shortTip.id }>
                                    <img id={ shortTip.id } src={ IMAGES_URL + shortTip.image} alt={shortTip.title}/>

                                    <h3>{shortTip.title} ({shortTip.author})</h3>
                            </div>
                                            ))}
                    </Flickity>
                </div>

            ) : 
            <div>&nbsp;</div>
        }

        <div className="article">
            { Map }
        </div>
    </div>
}

export default AttractionScreen;