import {
    TIP_LIST_REQUEST, 
    TIP_LIST_SUCCESS, 
    TIP_LIST_FAIL, 
    TIP_DETAILS_REQUEST, 
    TIP_DETAILS_SUCCESS, 
    TIP_DETAILS_FAIL,
    TIP_SAVE_REQUEST,
    TIP_SAVE_SUCCESS,
    TIP_SAVE_FAIL,
    TIP_DELETE_SUCCESS,
    TIP_DELETE_FAIL,
    TIP_DELETE_REQUEST,
    TIP_FILTER_SUCCESS,
    TIP_FILTER_FAIL,
    TIP_FILTER_REQUEST,
    SHORT_TIP_LIST_REQUEST, 
    SHORT_TIP_LIST_SUCCESS, 
    SHORT_TIP_LIST_FAIL, 
    SHORT_TIP_DETAILS_REQUEST, 
    SHORT_TIP_DETAILS_SUCCESS, 
    SHORT_TIP_DETAILS_FAIL,
    SHORT_TIP_SAVE_REQUEST,
    SHORT_TIP_SAVE_SUCCESS,
    SHORT_TIP_SAVE_FAIL,
    SHORT_TIP_DELETE_SUCCESS,
    SHORT_TIP_DELETE_FAIL,
    SHORT_TIP_DELETE_REQUEST,
    SHORT_TIP_FILTER_SUCCESS,
    SHORT_TIP_FILTER_FAIL,
    SHORT_TIP_FILTER_REQUEST,
    SHORT_TIP_AUTHOR_FILTER_SUCCESS,
    SHORT_TIP_AUTHOR_FILTER_FAIL,
    SHORT_TIP_AUTHOR_FILTER_REQUEST,
} from "../constants/tipConstants"
import { BACKEND_URL } from "../constants/generalConstants"
import axios from 'axios';

const listTips = () => async (dispatch) => {
try {
    dispatch({ type: TIP_LIST_REQUEST });
    const { data } = await axios.get( BACKEND_URL + "/tips");
    dispatch({ type: TIP_LIST_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: TIP_LIST_FAIL, payload: error.message });
    }
}

const filterTips = (attraction) => async (dispatch) => {
  try {
      dispatch({ type: TIP_FILTER_REQUEST });
      const { data } = await axios.get( BACKEND_URL + "/tips/" + attraction);
      dispatch({ type: TIP_FILTER_SUCCESS, payload: data })
      }
      catch (error) {
          dispatch({ type: TIP_FILTER_FAIL, payload: error.message });
      }
  }

const detailsTip = (tipId) => async (dispatch) => {
    try {
        dispatch({type: TIP_DETAILS_REQUEST, payload: tipId});
        const {data} = await axios.get( BACKEND_URL + "/tip/" + tipId);
        dispatch({type: TIP_DETAILS_SUCCESS, payload: data});
    } catch (error) {
        dispatch({ type: TIP_DETAILS_FAIL, payload: error.message });
    }
}

const saveTip = (tip) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIP_SAVE_REQUEST, payload: tip });
      const {
        userSignin: { userInfo },
      } = getState();
      if (!tip.id) {
        const { data } = await axios.post( BACKEND_URL + "/tip/add", tip, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: TIP_SAVE_SUCCESS, payload: data });
      } else {
        const { data } = await axios.put(
          BACKEND_URL + "/tip/update",
          tip,
          {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          }
        );
        dispatch({ type: TIP_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: TIP_SAVE_FAIL, payload: error.message });
    }
};

const deleteTip = (tipId) => async (dispatch, getState) => {
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      dispatch({ type: TIP_DELETE_REQUEST, payload: tipId });
      const { data } = await axios.delete( BACKEND_URL + "/tip/delete/" + tipId, {
        headers:
        { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
      });
      dispatch({ type: TIP_DELETE_SUCCESS, payload: data, success: true });
    } catch (error) {
      dispatch({ type: TIP_DELETE_FAIL, payload: error.message });
    }
};

const listShortTips = () => async (dispatch) => {
  try {
      dispatch({ type: SHORT_TIP_LIST_REQUEST });
      const { data } = await axios.get( BACKEND_URL + "/shortTips");
      dispatch({ type: SHORT_TIP_LIST_SUCCESS, payload: data })
      }
      catch (error) {
          dispatch({ type: SHORT_TIP_LIST_FAIL, payload: error.message });
      }
  }
  
  const filterShortTips = (attraction) => async (dispatch) => {
    try {
        dispatch({ type: SHORT_TIP_FILTER_REQUEST });
        const { data } = await axios.get( BACKEND_URL + "/shortTips/" + attraction);
        dispatch({ type: SHORT_TIP_FILTER_SUCCESS, payload: data })
        }
        catch (error) {
            dispatch({ type: SHORT_TIP_FILTER_FAIL, payload: error.message });
        }
    }
  
  const filterAuthorShortTips = (author) => async (dispatch, getState) => {
    try {
        const {
          userSignin: { userInfo },
        } = getState();
        dispatch({ type: SHORT_TIP_AUTHOR_FILTER_REQUEST, payload: author });
        const { data } = await axios.get( BACKEND_URL + "/shortTipsAuthor/" + author, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: SHORT_TIP_AUTHOR_FILTER_SUCCESS, payload: data })
        }
        catch (error) {
            dispatch({ type: SHORT_TIP_AUTHOR_FILTER_FAIL, payload: error.message });
        }
    }
  
  const detailsShortTip = (tipId) => async (dispatch) => {
      try {
          dispatch({type: SHORT_TIP_DETAILS_REQUEST, payload: tipId});
          const {data} = await axios.get( BACKEND_URL + "/shortTip/" + tipId);
          dispatch({type: SHORT_TIP_DETAILS_SUCCESS, payload: data});
      } catch (error) {
          dispatch({ type: SHORT_TIP_DETAILS_FAIL, payload: error.message });
      }
  }
  
  const saveShortTip = (shortTip) => async (dispatch, getState) => {

      try {
        dispatch({ type: SHORT_TIP_SAVE_REQUEST, payload: shortTip });
        const {
          userSignin: { userInfo },
        } = getState();
  
        if (!shortTip.id) {

          var date = new Date();
          var idNumber =  date.getTime();
          var id = idNumber.toString(); 
          shortTip.id = id;

          const { data } = await axios.post( BACKEND_URL + "/shortTip/add", shortTip, {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          });
          dispatch({ type: SHORT_TIP_SAVE_SUCCESS, payload: data });
        } else {

          const { data } = await axios.put(
            BACKEND_URL + "/shortTip/update",
            shortTip,
            {
              headers:
              { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
            }
          );
          dispatch({ type: SHORT_TIP_SAVE_SUCCESS, payload: data });
        }
      } catch (error) {
        dispatch({ type: SHORT_TIP_SAVE_FAIL, payload: error.message });
      }
  };
  
  const deleteShortTip = (tipId) => async (dispatch, getState) => {
      try {
        const {
          userSignin: { userInfo },
        } = getState();
        dispatch({ type: SHORT_TIP_DELETE_REQUEST, payload: tipId });
        const { data } = await axios.delete( BACKEND_URL + "/shortTip/delete/" + tipId, {
          headers:
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: SHORT_TIP_DELETE_SUCCESS, payload: data, success: true });
      } catch (error) {
        dispatch({ type: SHORT_TIP_DELETE_FAIL, payload: error.message });
      }
  };
  
export { listTips, detailsTip, deleteTip, saveTip, filterTips, listShortTips, detailsShortTip, deleteShortTip, saveShortTip, filterShortTips, filterAuthorShortTips }