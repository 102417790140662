import { List, ListItem } from "@material-ui/core";
import React from "react";
import enFlag from '../resources/gb.png';
import coFlag from '../resources/co.png';
import frFlag from '../resources/fr.png';

function LanguageSelector(props) {
  return (

    <div className="language-select">
                            <select
                              className="custom-select"
                              value={props.language}
                              onChange={e => props.handleSetLanguage(e.target.value)}
                            >
                              <option value="en">English</option>
                              <option value="es">Español</option>
                            </select>    
                            </div>

  );
}

export default LanguageSelector;