import {ACTIVITY_LIST_REQUEST, 
    ACTIVITY_LIST_SUCCESS, 
    ACTIVITY_LIST_FAIL,
    ACTIVITY_SAVE_REQUEST,
    ACTIVITY_SAVE_SUCCESS,
    ACTIVITY_SAVE_FAIL,
    ACTIVITY_DELETE_REQUEST,
    ACTIVITY_DELETE_SUCCESS,
    ACTIVITY_DELETE_FAIL} from "../constants/activityConstants"
import { BACKEND_URL } from "../constants/generalConstants"
import axios from 'axios';

const listActivities = () => async (dispatch) => {
try {
    dispatch({ type: ACTIVITY_LIST_REQUEST });
    const { data } = await axios.get( BACKEND_URL + "/activities");
    dispatch({ type: ACTIVITY_LIST_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: ACTIVITY_LIST_FAIL, payload: error.message });
    }
}

const saveActivity = (activity) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIVITY_SAVE_REQUEST, payload: activity });
      const {
        userSignin: { userInfo },
      } = getState();
      if (!activity.name) {
        const { data } = await axios.post( BACKEND_URL + "/activity/add", activity, {
          headers: 
          { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
        });
        dispatch({ type: ACTIVITY_SAVE_SUCCESS, payload: data });
      } else {
        const { data } = await axios.put(
          BACKEND_URL + "/activity/update",
          activity,
          {
            headers:
            { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
          }
        );
        dispatch({ type: ACTIVITY_SAVE_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({ type: ACTIVITY_SAVE_FAIL, payload: error.message });
    }
};

const deleteActivity = (activityId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: ACTIVITY_DELETE_REQUEST, payload: activityId });
    const { data } = await axios.delete( BACKEND_URL + "/activity/delete/" + activityId, {
      headers:
      { Authorization: 'Bearer ' + userInfo.token + " " + userInfo.email + " " + userInfo.role },
    });
    dispatch({ type: ACTIVITY_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: ACTIVITY_DELETE_FAIL, payload: error.message });
  }
};

export { listActivities, saveActivity, deleteActivity }