import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { saveBooking } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';

function BookingScreen(props) {

  let content = {

    English: {
      details : "Booking details",
      contactNumber : "Contact number",
      nationality : "Nationality",
      groupDetails : "Group details: Name, age, and weight of the people who will take the activity",
      name : "Name",
      continue : "Continue",
    },

    Español: {
      details : "Detalles de la reserva",
      contactNumber : "Número de contacto",
      nationality : "Nacionalidad",
      groupDetails : "Detalles del grupo:  Nombre, edad y peso de las personas que harán la actividad",
      name : "Nombre",
      continue : "Continuar",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [bookingName, setBookingName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [groupDetails, setGroupDetails] = useState('');
  const [nationality, setNationality] = useState('');

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveBooking({ bookingName, contactNumber, groupDetails, nationality }));
    props.history.push('/' + props.language + '/' + 'payment');
  }

  ReactGA.initialize(GA_ID);

  return <div>
    <CheckoutSteps step1 language={props.language} {...props}></CheckoutSteps>
    <div className="form">
      <form onSubmit={submitHandler} >
        <ul className="form-container">
          <li>
            <h2>{content.details}</h2>
          </li>

          <li>
            <label htmlFor="bookingName">
              {content.name}
          </label>
            <input type="text" name="bookingName" id="bookingName" onChange={(e) => setBookingName(e.target.value)}>
            </input>
          </li>
          <li>
            <label htmlFor="contactNumber">
              {content.contactNumber}
          </label>
            <input type="text" name="contactNumber" id="contactNumber" onChange={(e) => setContactNumber(e.target.value)}>
            </input>
          </li>
          <li>
            <label htmlFor="nationality">
            {content.nationality}
          </label>
            <input type="text" name="nationality" id="nationality" onChange={(e) => setNationality(e.target.value)}>
            </input>
          </li>
          <li>
            <label htmlFor="groupDetails">
              {content.groupDetails}
          </label>
            <textarea 
              name="groupDetails" 
              id="groupDetails" 
              value={groupDetails} 
              rows="10"
              onChange={(e) => setGroupDetails(e.target.value)}>
            </textarea>
          </li>


          <li>
            <button type="submit" className="button primary">{content.continue}</button>
          </li>

        </ul>
      </form>
    </div>
  </div>

}
export default BookingScreen;