import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  saveShortTip,
  filterAuthorShortTips,
  deleteShortTip,
} from '../actions/tipActions';
import { listAttractions } from '../actions/attractionActions';
import { BACKEND_URL } from "../constants/generalConstants"
import ReactGA from 'react-ga4';
import { IMAGES_URL } from '../constants/generalConstants';
import MetaTags from 'react-meta-tags';
import { SITE_NAME, SITE_URL, GA_ID } from '../constants/generalConstants';
import logo from '../resources/logo192.png';

function CreateShortTipScreen(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState('');
  const [parent, setParent] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [owner, setOwner] = useState('');
  const [author, setAuthor] = useState('');
  const [newHost, setNewHost] = useState('');
  const [latPos, setLatPos] = useState('init');
  const [longPos, setLongPos] = useState('init');

  const [uploading, setUploading] = useState(false);

  const shortTipAuthorFilter = useSelector((state) => state.shortTipAuthorFilter);
  const { loading, tips, error } = shortTipAuthorFilter;
  const attractionList = useSelector(state => state.attractionList);
  const {attractions, loadingAttractions, errorAttractions} = attractionList;
  const userSignin = useSelector(state=>state.userSignin);
  const {userInfo} = userSignin;

  const shortTipSave = useSelector((state) => state.shortTipSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = shortTipSave;

  const shortTipDelete = useSelector((state) => state.shortTipDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = shortTipDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    if (userInfo) {
      dispatch(filterAuthorShortTips(userInfo.email));
      dispatch(listAttractions());
    } else {
      props.history.push("/");
    }

    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (shortTip) => {
    setModalVisible(true);
    setId(shortTip.id);
    setParent(shortTip.parent);
    setTitle(shortTip.title);
    setImage(shortTip.image);
    setOwner(shortTip.owner);
    setAuthor(shortTip.author);
    setNewHost(shortTip.newHost);
    navigator.geolocation.getCurrentPosition(function(position) {
      setLatPos(position.coords.latitude.toString());
      setLongPos(position.coords.longitude.toString());
    });
  };

  const submitHandler = (e) => {

    if (title && parent && userInfo) {
        var owner = userInfo.email;
        var author = userInfo.name;
  
      dispatch(
        saveShortTip({
          id,
          parent,
          title,
          image,
          owner,
          author,
          newHost,
          latPos,
          longPos,
        })
      );
    } else {
      alert("Please, fill out the information!")
    } 
  };

  const deleteHandler = (shortTip) => {
    dispatch(deleteShortTip(shortTip.id));
  };

  function refreshImage(imgElement, imgURL){    
    var timestamp = new Date().getTime();    
    var el = document.getElementById(imgElement);  
    var queryString = "?t=" + timestamp;
    el.src = IMAGES_URL + imgURL + queryString;    
  }  

  const rotateCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/cw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const rotateCCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/ccw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post( BACKEND_URL + '/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const calcDate = (unixTime) => {
    var numberUnixTime = parseInt(unixTime);
    var dateResult = new Date(numberUnixTime);
    var dateText = dateResult.toLocaleString();
    return dateText;
  }

  ReactGA.initialize(GA_ID);

  return (
    <div className="wrapper">
            <MetaTags>
                <title>Create a review of our ATTRACTIONS!</title>
                <meta name="description" content="Share with us your experience with our ATTRACTIONS!" />
                <meta property="og:site_name" content={SITE_NAME} />;
                <meta property="og:type" content="website" />;
                <meta property="og:url" content={SITE_URL} />;
                <meta property="og:title" content="Create  a review of our ATTRACTIONS!" />
                <meta property="og:description" content="Share with us your experience with our ATTRACTIONS!" />;
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
            </MetaTags>
            <div className="content-margined">
              <div className="product-header">
                <h3>My Reviews</h3>
                <button className="button primary" onClick={() => openModal({})}>
                  New Review
                </button>
              </div>
              {modalVisible && (
                <div className="form">
                  <form onSubmit={submitHandler}>
                      <ul className="form-container">
                        <li>
                          <h2>Create new Review</h2>
                        </li>
                        <li>
                          {loadingSave && <div>Loading...</div>}
                          {errorSave && <div>{errorSave}</div>}
                        </li>

                        <li>
                          <label htmlFor="attraction">Attraction</label>
                          {attractions!=null? 
                          (
                            <select value={parent} onChange={(e) => setParent(e.target.value)} onClick={(e) => setParent(e.target.value)}>                    
                              <option value="">Choose an Attraction</option>
                              <option key="New Attraction" value="new-attraction">New Attraction</option>
                              {attractions.map((attraction) => (
                                  <option key={attraction.id} value={attraction.id}>{attraction.name}</option>
                              ))}
                            </select>
                          ) :
                          <div>No Attractions</div>}
                        </li>
                        <li>
                          <div className="showImage">
                            {!image ? <b>&nbsp;</b> : 
                              <div>
                                <img id="articlePic" src={ IMAGES_URL + image} alt="Tip picture" className="showImage"/>
                                <b onClick={() => { rotateCCWHandler(image) }}>Left</b>
                                <b> -Rotate- </b>
                                <b onClick={() => { rotateCWHandler(image) }}>Right</b>
                              </div>
                          }
                          </div> 
                        </li>
                        <li>
                          <label htmlFor="image">Picture</label>
                          <input
                            type="text"
                            name="image"
                            value={image}
                            id="image"
                            placeholder="Take it with your camera, or upload a file..."
                            onChange={(e) => setImage(e.target.value)}
                          ></input>
                          <input type="file" onChange={uploadFileHandler}></input>
                          {uploading && <h2>Uploading...</h2>}
                        </li>
                        <li>
                          <label htmlFor="title">Review</label>
                          <textarea
                            rows="7"
                            name="title"
                            value={title}
                            id="title"
                            placeholder="Tell us about what's going on in the picture!"
                            onChange={(e) => setTitle(e.target.value)}
                          ></textarea>
                        </li>
                        <li>
                        <fieldset data-role="controlgroup" data-type="horizontal" data-role="fieldcontain">
                            <label htmlFor="newHost">Would you like to become a new Attractions Tips HOST?</label>
                            <div>&nbsp;</div>
                            <div>
                            <label>
                              <input
                                type="radio"
                                value="Yes"
                                checked={newHost === "Yes"}
                                onChange={(e) => setNewHost(e.target.value)}
                              />
                              &nbsp;&nbsp;Yes, I do!
                            </label>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                            <label>
                              <input
                                type="radio"
                                value="No"
                                checked={newHost === "No"}
                                onChange={(e) => setNewHost(e.target.value)}
                              />
                              &nbsp;&nbsp;Just sharing...
                            </label>
                            </div>

                        </fieldset>
                        </li>
                        <li>
                          <button type="submit" className="button primary">
                            {title ? 'Update' : 'Create'}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => setModalVisible(false)}
                            className="button secondary"
                          >
                            Close
                          </button>
                        </li>
                      </ul>
                  </form>
                </div>
              )}

              { loading? <div className="article"><img className="responsive" src={logo} alt="Attraction Tips"></img></div>:
                    error? <div>{error}</div>:
                    tips!=null? 
                    (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Attraction</th>
                              <th>Author</th>
                              <th>Owner</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tips.map((shortTip) => (
                              <tr key={shortTip.id}>
                                <td>
                                    <img  className="thumbnail" src={ IMAGES_URL + shortTip.image} alt="attraction" />
                                </td> 
                                <td>{shortTip.title}</td>
                                <td>{shortTip.parent}</td>
                                <td>{shortTip.author}</td>
                                <td>{shortTip.owner}</td>
                                <td>{ calcDate(shortTip.id) }</td>
                                <td>
                                  <button className="button" onClick={() => openModal(shortTip)}>
                                    Edit
                                  </button>{' '}
                                  <button
                                    className="button"
                                    onClick={() => { if (window.confirm("Are you sure to delete " + shortTip.title + "?")) deleteHandler(shortTip)} }
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                    ) : 
                    <div>No Tips</div>
              }
            </div>
    </div>
  );
}

export default CreateShortTipScreen;