import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  saveAttraction,
  listAttractions,
  deleteAttraction,
} from '../actions/attractionActions';
import {
  listUsers,
} from '../actions/userActions';
import { listCountries } from '../actions/countryActions';
import { listActivities } from '../actions/activityActions';
import { filterLocations } from '../actions/locationActions';
import { BACKEND_URL } from "../constants/generalConstants"
import ReactGA from 'react-ga4';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IMAGES_URL } from '../constants/generalConstants';
import { GA_ID } from '../constants/generalConstants';

function AttractionsScreen(props) {

  let content = {

    English: {
      attractions : "Attractions",
      createAttraction : "Create Attraction",
      activity : "Activity",
      country : "Country",
      location : "Location",
      edit : "Edit",
      name : "Name",
      owner : "Owner",
      chooseUser     : "Choose an User",
      title     : "Title",
      description     : "Description",
      image     : "Image",
      imageAlt     : "Image Alt",
      article     : "Article",
      create     : "Create",
      update     : "Update",
      back     : "Back",
      delete     : "Delete",
      activityBy  : "Activity by",
      price  : "Price",
      maxUsers  : "Max users per day",
    },

    Español: {
      attractions : "Atracciones",
      createAttraction : "Crear Atracción",
      activity : "Actividad",
      country : "País",
      location : "Locación",
      edit : "Editar",
      name : "Nombre",
      owner : "Propietario",
      chooseUser     : "Escoge un ususario",
      title     : "Título",
      description     : "Descripción",
      image     : "Imagen",
      imageAlt     : "Imagen Alt",
      article     : "Artículo",
      create     : "Crear",
      update     : "Actualizar",
      back     : "Atrás",
      delete     : "Borrar",
      activityBy  : "Actividad por",
      price  : "Precio",
      maxUsers  : "Max usuarios por día",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [modalVisible, setModalVisible] = useState(false);
  const [country, setCountry] = useState('');
  const [parent, setParent] = useState('');
  const [activityBy, setActivityBy] = useState('');
  const [name, setName] = useState('');
  const [metaName, setMetaName] = useState('');
  const [metaNameEs, setMetaNameEs] = useState('');
  const [activity, setActivity] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [image, setImage] = useState('');
  const [imageAlt, setImageAlt] = useState('');
  const [imageAltEs, setImageAltEs] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEs, setDescriptionEs] = useState('');
  const [article, setArticle] = useState('');
  const [articleEs, setArticleEs] = useState('');
  const [owner, setOwner] = useState('');
  const [maxUsersDay, setMaxUsersDay] = useState('');
  const [latPos, setLatPos] = useState('init');
  const [longPos, setLongPos] = useState('init');

  const [uploading, setUploading] = useState(false);
  const attractionList = useSelector((state) => state.attractionList);
  const { loading, attractions, error } = attractionList;
  const countryList = useSelector((state) => state.countryList);
  const { loadingCountries, countries, errorCountries } = countryList;
  const locationFilter = useSelector(state => state.locationFilter);
  const {locations, loadingLocations, errorLocations} = locationFilter;
  const activityList = useSelector((state) => state.activityList);
  const { loadingActivities, activities, errorActivities } = activityList;
  const userList = useSelector((state) => state.userList);
  const { loadingUsers, users, errorUsers } = userList;

  const attractionSave = useSelector((state) => state.attractionSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = attractionSave;

  const attractionDelete = useSelector((state) => state.attractionDelete);
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = attractionDelete;
  const dispatch = useDispatch();

  useEffect(() => {
    if (successSave) {
      setModalVisible(false);
    }
    dispatch(listAttractions());
    dispatch(listCountries());
    dispatch(listActivities());
    dispatch(listUsers());
    reloadLocations(country);

    if (country != '') {
      dispatch(filterLocations(country));
    }

    return () => {
      //
    };
  }, [successSave, successDelete]);

  const openModal = (attraction) => {
    setModalVisible(true);
    setParent(attraction.parent);
    setCountry(attraction.country);
    setActivityBy(attraction.activityBy);
    setName(attraction.name);
    setMetaName(attraction.metaName);
    setMetaNameEs(attraction.metaNameEs);
    setActivity(attraction.activity);
    setVideoUrl(attraction.videoUrl);
    setSiteUrl(attraction.siteUrl);
    setImage(attraction.image);
    setImageAlt(attraction.imageAlt);
    setImageAltEs(attraction.imageAltEs);
    setPrice(attraction.price);
    setDescription(attraction.description);
    setDescriptionEs(attraction.descriptionEs);
    setMaxUsersDay(attraction.maxUsersDay);
    setArticle(attraction.article);
    setArticleEs(attraction.articleEs);
    setOwner(attraction.owner);
    setLatPos(attraction.latPos);
    setLongPos(attraction.longPos);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    var id = name.replace(/\s/g, '-') ;
    var result = id.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'') ;
    id = result.toLowerCase();
    id = id.replace('ñ', 'n') ;

    var path = '/' + country.toLowerCase() + '/' + parent.toLowerCase() + '/' + id;

    dispatch(
      saveAttraction({
        id,
        parent,
        country,
        path,
        name,
        metaName,
        metaNameEs,
        activity,
        activityBy,
        siteUrl,
        videoUrl,
        image,
        imageAlt,
        imageAltEs,
        price,
        description,
        descriptionEs,
        article,
        articleEs,
        maxUsersDay,
        owner,
        latPos,
        longPos,
      })
    );
  };

  const deleteHandler = (attraction) => {
    dispatch(deleteAttraction(attraction.id));
  };

  const reloadLocations = (country) => {
    setCountry(country)
    dispatch(filterLocations(country));
    setParent()
  };

  function refreshImage(imgElement, imgURL){    
    var timestamp = new Date().getTime();    
    var el = document.getElementById(imgElement);  
    var queryString = "?t=" + timestamp;
    el.src = IMAGES_URL + imgURL + queryString;    
  }  

  const rotateCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/cw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const rotateCCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/ccw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post( BACKEND_URL + '/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const editorConfiguration = {
    heading: {
      options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      ]
    },

  };

  ReactGA.initialize(GA_ID);

  return (
    <div className="content content-margined">
      <div className="product-header">
        <h3>{content.attractions}</h3>
        <button className="button primary" onClick={() => openModal({})}>
          {content.createAttraction}
        </button>
      </div>
      {modalVisible && (
        <div className="form">
          <form onSubmit={submitHandler}>
            <ul className="form-container">
              <li>
                <h2>{content.createAttraction}</h2>
              </li>
              <li>
                {loadingSave && <div>Loading...</div>}
                {errorSave && <div>{errorSave}</div>}
              </li>
              <li>
                <label htmlFor="owner">{content.owner}</label>
                {users!=null? 
                (
                  <select value={owner} onChange={(e) => setOwner(e.target.value)}>
                      <option value="">{content.chooseUser}</option>
                      {users.map((user) => (
                          <option key={user.email} value={user.email}>{user.email}</option>
                      ))}
                  </select>
                ) :
                <div>No Users...</div>}
              </li>
              <li>
                <label htmlFor="activity">{content.activity}</label>
                {activities!=null? 
                (
                  <select value={activity} onChange={(e) => setActivity(e.target.value)} onClick={(e) => setActivity(e.target.value)}>                    
                    <option value="">Choose an Activity</option>
                    {activities.map((activity) => (
                        <option key={activity.name} value={activity.name}>{activity.name}</option>
                    ))}
                  </select>
                ) :
                <div>No Activities</div>}
              </li>
              <li>
                <label htmlFor="country">{content.country}</label>
                {countries!=null? 
                (
                  <select value={country} onChange={(e) => reloadLocations(e.target.value)}>
                      <option value="">Choose a Country</option>
                      {countries.map((country) => (
                          <option key={country.id} value={country.id}>{country.name}</option>
                      ))}
                  </select>
                ) :
                <div>No Countries</div>}
              </li>
              <li>
                <label htmlFor="location">{content.location}</label>
                {locations!=null? 
                (
                  <select value={parent} onChange={(e) => setParent(e.target.value)} onClick={(e) => setParent(e.target.value)}>                    
                    <option value="">Choose a Location</option>
                    {locations.map((location) => (
                        <option key={location.id} value={location.id}>{location.name}</option>
                    ))}
                  </select>
                ) :
                <div>No Locations</div>}
              </li>
              <li>
                <label htmlFor="name">{content.name}</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </li>
              {
                props.language === "en"
                ?  <li>
                      <label htmlFor="metaName">{content.title} ({props.language})</label>
                      <textarea
                        rows="5"
                        name="metaName"
                        value={metaName}
                        id="metaName"
                        onChange={(e) => setMetaName(e.target.value)}
                      ></textarea>
                    </li>
                :  <li>
                      <label htmlFor="metaNameEs">{content.title} ({props.language})</label>
                      <textarea
                        rows="5"
                        name="metaNameEs"
                        value={metaNameEs}
                        id="metaNameEs"
                        onChange={(e) => setMetaNameEs(e.target.value)}
                      ></textarea>
                    </li>
              }

              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="description">{content.description} ({props.language})</label>
                    <textarea
                      rows="8"
                      name="description"
                      value={description}
                      id="description"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </li>
                :  <li>
                    <label htmlFor="descriptionEs">{content.description} ({props.language})</label>
                    <textarea
                      rows="8"
                      name="descriptionEs"
                      value={descriptionEs}
                      id="descriptionEs"
                      onChange={(e) => setDescriptionEs(e.target.value)}
                    ></textarea>
                  </li>
              }
              <li>
                <label htmlFor="activityBy">
                  {content.activityBy}
                </label>
                <input value={activityBy} type="activityBy" name="activityBy" id="activityBy" onChange={(e) => setActivityBy(e.target.value)}>
                </input>
              </li>
              <li>
                <label htmlFor="siteUrl">
                  Web Site
                </label>
                <input value={siteUrl} type="siteUrl" name="siteUrl" id="siteUrl" onChange={(e) => setSiteUrl(e.target.value)}>
                </input>
              </li>
              <li>
                <label htmlFor="videoUrl">
                  Video Link
                </label>
                <input value={videoUrl} type="videoUrl" name="videoUrl" id="videoUrl" onChange={(e) => setVideoUrl(e.target.value)}>
                </input>
              </li>
              <li>
                <div className="showImage">
                  {!image ? <b>&nbsp;</b> : 
                    <div>
                      <img id="articlePic" src={ IMAGES_URL + image} alt="Attraction picture" className="showImage"/>
                      <b onClick={() => { rotateCCWHandler(image) }}>Left</b>
                      <b> -Rotate- </b>
                      <b onClick={() => { rotateCWHandler(image) }}>Right</b>
                    </div>
                  }
                </div> 
              </li>
              <li>
                <label htmlFor="image">{content.image}</label>
                <input
                  type="text"
                  name="image"
                  value={image}
                  id="image"
                  onChange={(e) => setImage(e.target.value)}
                ></input>
                <input type="file" onChange={uploadFileHandler}></input>
                {uploading && <h2>Uploading...</h2>}
              </li>
              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="imageAlt">{content.imageAlt} ({props.language})</label>
                    <input
                      type="text"
                      name="imageAlt"
                      value={imageAlt}
                      id="imageAlt"
                      onChange={(e) => setImageAlt(e.target.value)}
                    ></input>
                  </li>
                :  <li>
                    <label htmlFor="imageAltEs">{content.imageAlt} ({props.language})</label>
                    <input
                      type="text"
                      name="imageAltEs"
                      value={imageAltEs}
                      id="imageAltEs"
                      onChange={(e) => setImageAltEs(e.target.value)}
                    ></input>
                  </li>
              }
              <li>
                <label htmlFor="price">{content.price}</label>
                <input
                  type="text"
                  name="price"
                  value={price}
                  id="price"
                  onChange={(e) => setPrice(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="maxUsersDay">{content.maxUsers}</label>
                <input
                  type="text"
                  name="maxUsersDay"
                  value={maxUsersDay}
                  id="maxUsersDay"
                  onChange={(e) => setMaxUsersDay(e.target.value)}
                ></input>
              </li>
              {
                props.language === "en"
                ?  <li>
                    <label htmlFor="article">{content.article} ({props.language})</label>
                    <CKEditor 
                        editor={ ClassicEditor }
                        config={editorConfiguration}
                        data={article}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setArticle(data);
                        } }
                    />
                  </li>
                :  <li>
                    <label htmlFor="articleEs">{content.article} ({props.language})</label>
                    <CKEditor 
                        editor={ ClassicEditor }
                        config={editorConfiguration}
                        data={articleEs}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setArticleEs(data);
                        } }
                    />
                  </li>
              }
              <li>
                <label htmlFor="latitude">Latitude</label>
                <input
                  type="text"
                  name="latitude"
                  value={latPos}
                  id="latitude"
                  onChange={(e) => setLatPos(e.target.value)}
                ></input>
              </li>
              <li>
                <label htmlFor="longitude">Longitude</label>
                <input
                  type="text"
                  name="longitude"
                  value={longPos}
                  id="longitude"
                  onChange={(e) => setLongPos(e.target.value)}
                ></input>
              </li>
              <li>
                <button type="submit" className="button primary">
                  {name ? 'Update' : 'Create'}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="button secondary"
                >
                  {content.back}
                </button>
              </li>
            </ul>
          </form>
        </div>
      )}

      { loading? <div>Loading...</div>:
            error? <div>{error}</div>:
            attractions != null ?
            (
              <div className="product-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{content.name}</th>
                      <th>{content.activity}</th>
                      <th>{content.country}</th>
                      <th>{content.location}</th>
                      <th>{content.price}</th>
                      <th>{content.owner}</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {attractions.map((attraction) => (
                      <tr key={attraction.id}>
                        <td><a href={"/" + props.language + attraction.path}> {attraction.name}</a></td>
                        <td>{attraction.activity}</td>
                        <td>{attraction.country}</td>
                        <td>{attraction.parent}</td>
                        <td>{attraction.price}</td>
                        <td>{attraction.owner}</td>
                        <td>
                          <button className="button" onClick={() => openModal(attraction)}>
                            Edit
                          </button>{' '}
                          <button
                            className="button"
                            onClick={() => { if (window.confirm("Are you sure to delete " + attraction.name + "?")) deleteHandler(attraction)} }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) :
            <div>No Attractions</div>
      }
      </div>

  );
}

export default AttractionsScreen;