import React, { useEffect, useState } from 'react';
import CheckoutSteps from '../components/CheckoutSteps';
import { PAYU_MERCHANT_ID, PAYU_API_KEY } from '../constants/generalConstants';

function ResponseScreen(props) {
    let content = {

        English: {
          description : "Description",
          paymentResult : "Payment Result",
          paymentMethod : "Payment Method",
          cart : "Booking",
          name : "Name",
          contactNumber : "Contact number",
          nationality : "Nationality",
          details : "Description",
          date : "Booking date",
          thanks : "Thanks for placing your booking!",
          placeOrder : "Place booking",
          summary : "Order Summary",
          total : "Order Total",
          price : "Price each",
          paid : "Paid",
          notPaid : "Not paid",
          payPayU : "Pay with PayU",
        },
    
        Español: {
          description : "Descripcion",
          paymentResult : "Resultado del Pago",
          paymentMethod : "Método de pago",
          cart : "Lista de reserva",
          name : "Nombre",
          contactNumber : "Número de contacto",
          nationality : "Nacionalidad",
          details : "Descripcion",
          date : "Fecha de reserva",
          thanks : "Gracias por reservar!",
          placeOrder : "Confirmar reserva",
          summary : "Resumen de la reserva",
          total : "Total",
          price : "Precio cada uno",
          paid : "Pagado",
          notPaid : "No pagado",
          payPayU : "Pagar con PayU",
        }
    
    }; 
    
    props.language === "en"
    ? (content = content.English)
    : (content = content.Español);
    
    let payUresponse = {
      merchantId : props.location.search? String(props.location.search.split("&")[0].split("=")[1]): "",
      referenceCode : props.location.search? String(props.location.search.split("&")[8].split("=")[1]): "",
      tx_value : props.location.search? String(props.location.search.split("&")[28].split("=")[1]): "",
      currency : props.location.search? String(props.location.search.split("&")[30].split("=")[1]): "",
      transactionState : props.location.search? String(props.location.search.split("&")[5].split("=")[1]): "",

      description : props.location.search? String(props.location.search.split("&")[11].split("=")[1]): "",
      signature : props.location.search? String(props.location.search.split("&")[19].split("=")[1]): "",

      status : props.location.search? String(props.location.search.split("&")[7].split("=")[1]): "",
      referenceValue : props.location.search? String(props.location.search.split("&")[8].split("=")[1]): "",
      date : props.location.search? String(props.location.search.split("&")[42].split("=")[1]): "",
    }

    var md5 = require("md5");

    useEffect(() => {
      if (props.match.path == "/:lang/response/:id" && payUresponse.signature == md5(PAYU_API_KEY + "~" + PAYU_MERCHANT_ID + "~" + payUresponse.referenceCode + "~" + payUresponse.tx_value + "~USD" + "~" + payUresponse.transactionState)); {
        alert("PayU payment: " + payUresponse.status + ", for booking: " + payUresponse.referenceValue + "...  Value: " + payUresponse.tx_value + " " + payUresponse.currency + "...  Date: " + payUresponse.date);
      }
      return () => {
      };
    }, []);
  
      return payUresponse.status == "APPROVED"? <div>
        <CheckoutSteps step1 step2 step3 step4 language={props.language} {...props}></CheckoutSteps>
        <div className="placeorder">
                <h2>
                {content.thanks}
                </h2>
                <h3>
                {content.paymentResult}: {payUresponse.status}
                </h3>
                <h4>
                {content.details}
                </h4>
                <p>
                  {decodeURIComponent(payUresponse.description)}
                </p>
        </div>
      </div>:
      <div>
        <CheckoutSteps step1 step2 step3 language={props.language} {...props}></CheckoutSteps>
        <div className="placeorder">
                <h3>
                {content.paymentResult}: {payUresponse.status}
                </h3>
                <h4>
                {content.details}
                </h4>
                <p>
                  {decodeURIComponent(payUresponse.description)}
                </p>
        </div>
      </div>
}

export default ResponseScreen;
