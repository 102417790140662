import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logout, update } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';
import axios from 'axios';
import { BACKEND_URL } from "../constants/generalConstants"
import { IMAGES_URL } from '../constants/generalConstants';

function ProfileScreen(props) {
  let content = {

    English: {
        userProfile : "User Profile",
        name : "Name",
        picture : "Picture",
        name : "Name",
        email : "Email",
        imagePlaceholder     : "Take it with your camera, or upload a file...",
        update     : "Update",
        logout     : "Logout",
        password     : "Password",
    },

    Español: {
      userProfile : "Perfil de usuario",
      name : "Nombre",
      picture : "Imagen",
      name : "Nombre",
        email : "Email",
        imagePlaceholder     : "Tómala con tu cámara, o sube un archivo...",
        sendMessage     : "Enviar mensaje...",
        update     : "Actualizar",
        logout     : "Salir",
        password     : "Contraseña",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [webSite, setWebSite] = useState('');
  const [image, setImage] = useState('');
  const [imageAlt, setImageAlt] = useState('');
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;
  const handleLogout = () => {
    dispatch(logout());
    props.history.push("/");
  }
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(update({ email, name, password, role, webSite, image, imageAlt }))
  }

  const calcDate = (unixTime) => {
    var numberUnixTime = parseInt(unixTime);
    var dateResult = new Date(numberUnixTime);
    var dateText = dateResult.toLocaleString();
    return dateText;
  }

  const userUpdate = useSelector(state => state.userUpdate);
  const { loading, success, error } = userUpdate;

  const myOrderList = useSelector(state => state.myOrderList);
  const { loading: loadingOrders, orders, error: errorOrders } = myOrderList;
  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setName(userInfo.name);
      setPassword(userInfo.password);
    }
    dispatch(listMyOrders());
    return () => {

    };
  }, [userInfo])

  function refreshImage(imgElement, imgURL){    
    var timestamp = new Date().getTime();    
    var el = document.getElementById(imgElement);  
    var queryString = "?t=" + timestamp;
    el.src = IMAGES_URL + imgURL + queryString;    
  }  

  const rotateCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/cw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const rotateCCWHandler = (image) => {
    axios.post( BACKEND_URL + "/rotate/ccw/" + image )
    .then((response) => {
      refreshImage("articlePic", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setUploading(true);
    axios
      .post( BACKEND_URL + '/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setImage(response.data);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  ReactGA.initialize(GA_ID);

  return <div className="profile">
    <div className="profile-info">
      <div className="form">
        <form onSubmit={submitHandler} >
          <ul className="form-container">
            <li>
              <h2>{content.userProfile}</h2>
            </li>
            <li>
              {loading && <div>Loading...</div>}
              {error && <div>{error}</div>}
              {success && <div>Profile Saved Successfully.</div>}
            </li>
            <li>
              <label htmlFor="name">
                {content.name}
              </label>
              <input value={name} type="name" name="name" id="name" onChange={(e) => setName(e.target.value)}>
              </input>
            </li>
            <li>
              <label htmlFor="email">
                {content.email}
              </label>
              <input value={email} type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)}>
              </input>
            </li>
            <li>
              <label htmlFor="webSite">
                Website
              </label>
              <input value={webSite} type="webSite" name="webSite" id="webSite" onChange={(e) => setWebSite(e.target.value)}>
              </input>
            </li>
            <li>
                          <div className="showImage">
                            {!image ? <b>&nbsp;</b> : 
                              <div>
                                <img id="articlePic" src={ IMAGES_URL + image} alt="Tip picture" className="showImage"/>
                                <b onClick={() => { rotateCCWHandler(image) }}>Left</b>
                                <b> -Rotate- </b>
                                <b onClick={() => { rotateCWHandler(image) }}>Right</b>
                              </div>
                          }
                          </div> 
                        </li>
                        <li>
                          <label htmlFor="image">{content.picture}</label>
                          <input
                            type="text"
                            name="image"
                            value={image}
                            id="image"
                            placeholder={content.imagePlaceholder}
                            onChange={(e) => setImage(e.target.value)}
                          ></input>
                          <input type="file" onChange={uploadFileHandler}></input>
                          {uploading && <h2>Uploading...</h2>}
                        </li>

            <li>
              <label htmlFor="password">{content.password}</label>
              <input value={password} type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}>
              </input>
            </li>

            <li>
              <button type="submit" className="button primary">{content.update}</button>
            </li>
            <li>
              <button type="button" onClick={handleLogout} className="button secondary full-width">{content.logout}</button>
            </li>

          </ul>
        </form>
      </div>
    </div>
    <div className="profile-orders content-margined">
      <h3>Bookings</h3>
      {
        loadingOrders ? <div>Loading...</div> :
          errorOrders ? <div>Please, log out and log in again.</div> :
            !orders ? <div>No bookings...</div> :
            <table className="table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                { orders.map(order => <tr key={order.idString}>
                  <td>{ calcDate(order.idString) }</td>
                  <td>{ order.totalPrice }</td>
                  <td>{ order.isPaid ? "Paid" : "Not Paid" }</td>
                  <td>
                    <Link to={"/order/" + order.idString}>DETAILS</Link>
                  </td>
                </tr>)}
              </tbody>
            </table>
      }
    </div>
      {userInfo.role!="admin"? <div>&nbsp;</div> :
        <div className="article">
          <ul className="form-container">
            <li><Link to={'/' + props.language + '/users'}>Users</Link></li>
            <li><Link to={'/' + props.language + '/countries'}>Countries</Link></li>
            <li><Link to={'/' + props.language + '/locations'}>Locations</Link></li>
            <li><Link to={'/' + props.language + '/attractions'}>Attractions</Link></li>
            <li><Link to={'/' + props.language + '/new-tips'}>Tips</Link></li>                   
            <li><Link to={'/' + props.language + '/activities'}>Activities</Link></li>
            <li><Link to={'/' + props.language + '/orders'}>Orders</Link></li>                   
          </ul>
        </div>
      }
    </div>
}

export default ProfileScreen;