import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { savePayment } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import ReactGA from 'react-ga4';
import { GA_ID } from '../constants/generalConstants';

function PaymentScreen(props) {
  let content = {

    English: {
      payment : "Payment",
      continue : "Continue",
    },

    Español: {
      payment : "Forma de pago",
      continue : "Continuar",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [paymentMethod, setPaymentMethod] = useState('');

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePayment({ paymentMethod }));
    props.history.push('/' + props.language + '/' + 'placeorder');
  };

  ReactGA.initialize(GA_ID);

  return (
    <div>
      <CheckoutSteps step1 language={props.language} {...props}></CheckoutSteps>
      <div className="form">
        <form onSubmit={submitHandler}>
          <ul className="form-container">
            <li>
              <h2>{content.payment}</h2>
            </li>

            <li>
              <div>
                <input
                  type="radio"
                  name="paymentMethod"
                  id="paymentMethod"
                  value="PayU"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></input>
                <label htmlFor="paymentMethod">PayU</label>
              </div>
            </li>

            <li>
              <button type="submit" className="button primary">
                {content.continue}
              </button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}
export default PaymentScreen;