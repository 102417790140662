import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { filterLocations } from '../actions/locationActions';
import { detailsCountry } from '../actions/countryActions';
import { IMAGES_URL } from '../constants/generalConstants';
import ReactGA from 'react-ga4';
import parse from 'html-react-parser';
import MetaTags from 'react-meta-tags';
import logo from '../resources/logo192.png';
import { SITE_NAME, SITE_URL, META_TITLE, GA_ID } from '../constants/generalConstants';

function LocationScreen( props ) {

    const locationFilter = useSelector(state => state.locationFilter);
    const {locations, loading, error} = locationFilter;
    const countryDetails = useSelector(state => state.countryDetails);
    const {country, loadingCountry, errorCountry} = countryDetails;
    const dispatch = useDispatch();
    const parse = require('html-react-parser');

    useEffect(() => {
        if (props.match.params.lang != props.language) { props.handleSetLanguage(props.match.params.lang) }
        dispatch(detailsCountry(props.match.params.country));
        dispatch(filterLocations(props.match.params.country));
        
        return () => {

        };
    }, [])

    function ScrollToTopOnMount() {
        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
      
        return null;
      }

    const linkList = loading? <div>&nbsp;</div>:
                     error? <div>{error}</div>:
                     !locations? <div>No locations...</div>:
            locations.map((location) => {
        return (
            <li key={location.id} value={location.id}>
                    <img className="product-image" src={ IMAGES_URL + location.image} alt={props.language=="en"? location.imageAlt : location.imageAltEs}></img>
                    <h3 className="product-name">{props.language=="en"? location.metaName : location.metaNameEs}</h3>
                    <div className="product-short">{props.language=="en"? location.description : location.descriptionEs}</div>
                    <div>&nbsp;</div>
                    <div className="callToAction">
                            <a href={`${props.match.url}/${location.id}`}>
                            <h4>{props.language=="en"? "Read more" : "Leer más"}</h4>
                        </a>
                    </div>
            </li>
        );
    });

    const articlePic = loadingCountry? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        errorCountry? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        !country? <img className="responsive" src={logo} alt={SITE_NAME}></img>:
                        props.language === "en"?
                        <img className="responsive" src={ IMAGES_URL + country.image} alt={country.imageAlt}></img>:
                        <img className="responsive" src={ IMAGES_URL + country.image} alt={country.imageAltEs}></img>;

    const article = loadingCountry? <div>&nbsp;</div>:
                    errorCountry? <div>{error}</div>:
                    !country? <div>&nbsp;</div>:
                    props.language === "en"?
                        parse(String(country.article)):
                        parse(String(country.articleEs));

    const metas = loadingCountry? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        errorCountry? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        !country? <MetaTags><title>{META_TITLE}</title></MetaTags>:
                        props.language === "en"?
                            <MetaTags>
                                <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                                    href={props.language=="en"?SITE_URL + "/es/" + country.id :SITE_URL + "/en/" + country.id} />
                                <link rel="alternate" hreflang="x-default"
                                    href={SITE_URL + "/en/" + country.id} />                            
                                <title>{ country.metaName }</title>
                                <meta name="description" content={ country.description } />
                                <meta property="og:site_name" content={SITE_NAME} />;
                                <meta property="og:type" content="website" />;
                                <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                                <meta property="og:title" content={ country.metaName } />
                                <meta property="og:description" content={ country.description } />;
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                            </MetaTags>:
                            <MetaTags>
                                <link rel="alternate" hreflang={props.language=="en"?"es":"en"}
                                    href={props.language=="en"?SITE_URL + "/es/" + country.id :SITE_URL + "/en/" + country.id} />
                                <link rel="alternate" hreflang="x-default"
                                    href={SITE_URL + "/en/" + country.id} />                            
                                <title>{ country.metaNameEs }</title>
                                <meta name="description" content={ country.descriptionEs } />
                                <meta property="og:site_name" content={SITE_NAME} />;
                                <meta property="og:type" content="website" />;
                                <meta property="og:url" content={`https://attractions.tips${props.match.url}`} />;
                                <meta property="og:title" content={ country.metaNameEs } />
                                <meta property="og:description" content={ country.descriptionEs } />;
                                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover"/>;
                            </MetaTags>

ReactGA.initialize(GA_ID);

    return (
        <div className="wrapper">
            { metas }
            <ScrollToTopOnMount />
            <div className="article">
                { articlePic }
                { article }
            </div>
            <ul className="products"> {linkList} </ul>

        </div> 
    );

};
export default LocationScreen;