import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrder, detailsOrder, payOrder } from '../actions/orderActions';
import { emptyCart } from '../actions/cartActions';
import { IMAGES_URL, SITE_URL } from '../constants/generalConstants';
import { PAYPAL_ID } from '../constants/generalConstants';
import { PAYU_MERCHANT_ID, PAYU_ACCOUNT_ID, PAYU_API_KEY, PAYU_URL, PAYU_CONFIRMATION_URL } from '../constants/generalConstants';

import ReactGA from 'react-ga4';
import { PayPalButton } from "react-paypal-button-v2";
import Countdown from 'react-countdown';
import { GA_ID } from '../constants/generalConstants';
import CheckoutSteps from '../components/CheckoutSteps';

function OrderScreen(props) {

  let content = {

    English: {
      details : "Booking details",
      payment : "Payment",
      paymentMethod : "Payment Method",
      cart : "Booking",
      name : "Name",
      contactNumber : "Contact number",
      nationality : "Nationality",
      groupDetails : "Group details",
      date : "Booking date",
      people : "People",
      placeOrder : "Place booking",
      summary : "Order Summary",
      total : "Order Total",
      price : "Price each",
      paid : "Paid",
      notPaid : "Not paid",
      payPayU : "Pay with PayU",
    },

    Español: {
      details : "Detalles de la reserva",
      payment : "Pago",
      paymentMethod : "Método de pago",
      cart : "Lista de reserva",
      name : "Nombre",
      contactNumber : "Número de contacto",
      nationality : "Nacionalidad",
      groupDetails : "Detalles del grupo",
      date : "Fecha de reserva",
      people : "Personas",
      placeOrder : "Confirmar reserva",
      summary : "Resumen de la reserva",
      total : "Total",
      price : "Precio cada uno",
      paid : "Pagado",
      notPaid : "No pagado",
      payPayU : "Pagar con PayU",
    }

}; 

props.language === "en"
? (content = content.English)
: (content = content.Español);

  const [payPalReady, setPayPalReady] = useState(false);
  const orderPay = useSelector(state => state.orderPay);
  const { loading: loadingPay, success: successPay, error: errorPay } = orderPay;
  const orderDetails = useSelector(state => state.orderDetails);
  const { loading, order, error } = orderDetails;
  const dispatch = useDispatch();
  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (successPay) {
      dispatch(emptyCart());
      props.history.push("/");
      alert("Your booking was successful!");
    } else {
      dispatch(detailsOrder(props.match.params.id));
    }
    if (errorPay) {
      alert("Payment failed!");
    }
    return () => {
    };
  }, [successPay, errorPay]);

  const handleSuccessPayment = (details, data) => {
    dispatch(payOrder(order, data));
  }

  const buttonReady = () => {
    setPayPalReady(true);

  }

  const Completionist = () => {
    window. location. reload(false);
  };

  var md5 = require("md5");

  ReactGA.initialize(GA_ID);

  return loading ? <div>Loading ...</div> : error ? <div>{error}</div> :

  <div>
    <CheckoutSteps step1 step2 step3 language={props.language} {...props}></CheckoutSteps>
    <div className="placeorder">
      <div className="placeorder-info">
        <div>
          <h3>
            {content.details}
        </h3>
          <div>
            {content.name}: {order.bookingName} -- {content.contactNumber}: {order.contactNumber} -- 
            {content.nationality}: {order.nationality}, -- {content.groupDetails}: {order.groupDetails}.
      </div>
    </div>

        <div>
          <ul className="cart-list-container">
            <li>
              <h3>
              {content.cart}
        </h3>
              <div>
              {content.price}
        </div>
            </li>
            {
              order.orderItems.length === 0 ?
                <div>
                  Cart is empty
        </div>
                :
                order.orderItems.map(item =>
                  <li key={item.name} value={item.name}>
                    <div className="cart-image">
                      <img src={ IMAGES_URL + item.image} alt="attraction" />
                    </div>
                    <div className="cart-name">
                      <div>
                        <Link to={"/" + props.language + item.path}>
                          {item.name}
                        </Link>

                      </div>
                      <div>
                        {content.date}: {item.date}
                      </div>
                      <div>
                        {content.people}: {item.qty}
                      </div>
                    </div>
                    <div className="cart-price">
                      ${item.price}
                    </div>
                  </li>
                )
            }
          </ul>
          
          </div>
            <div className="placeorder-action">
            <div>
          <h3>{content.payment}</h3>
          <div>
            {content.paymentMethod}: {order.paymentMethod}
          </div>
          <div>
            {order.isPaid ? content.paid : content.notPaid}
          </div>
          </div>

              <ul>
                <li className="placeorder-actions-payment">
                  {order.isPaid ? <b>{content.paid}...</b> :
                    order.paymentMethod === 'PayPal' ?
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={(details, data) => {
                        alert("Transaction completed by " + details.payer.name.given_name);
                        handleSuccessPayment(details, data);
                      }}
                      catchError={(err) => {
                        alert("Catch Error in transaction: " + err);
                      }}
                      onError={(err) => {
                        alert("On Error in transaction: " + err);
                      }}
                      onButtonReady={() => buttonReady()}
                      options={{
                        clientId: PAYPAL_ID
                      }}
                    />:
                    loading? <div>Loading...</div>:
                    error? <div>error...</div>:
                    !order? <div>No order...</div>:
                    <form method="post" action={PAYU_URL}>
                      <input name="merchantId"      type="hidden"  value={PAYU_MERCHANT_ID}></input>
                      <input name="accountId"       type="hidden"  value={PAYU_ACCOUNT_ID} ></input>
                      <input name="description"     type="hidden"  value={ order.orderItems.map(item => item.name + " -- " + item.date + " -- ") + content.name + ": " + order.bookingName + " -- " + content.contactNumber + ": " + order.contactNumber + " -- " + content.nationality + ": " + order.nationality + " -- " + content.groupDetails + ": " + order.groupDetails}  ></input>
                      <input name="referenceCode"   type="hidden"  value={ order.Id } ></input>
                      <input name="amount"          type="hidden"  value={order.totalPrice}   ></input>
                      <input name="tax"             type="hidden"  value="0"  ></input>
                      <input name="taxReturnBase"   type="hidden"  value="0" ></input>
                      <input name="currency"        type="hidden"  value="USD" ></input>
                      <input name="lng"             type="hidden"  value={props.language} ></input>
                      <input name="signature"       type="hidden"  value={md5(PAYU_API_KEY + "~" + PAYU_MERCHANT_ID + "~" + order.Id + "~" + order.totalPrice + "~USD")} ></input>
                      <input name="test"            type="hidden"  value="1" ></input>
                      <input name="buyerEmail"      type="hidden"  value={userInfo.email} ></input>
                      <input name="buyerFullName"   type="hidden"  value="APPROVED" ></input>
                      <input name="responseUrl"     type="hidden"  value={SITE_URL + "/" + props.language + "/response/" + order.Id} ></input>
                      <input name="confirmationUrl" type="hidden"  value={PAYU_CONFIRMATION_URL} ></input>
                      <input name="Submit"          type="submit"  value={content.payPayU} className="button primary full-width"></input>
                    </form>
                  }
                </li>

                <li>
                  <div>Total</div>
                  <div>${order.totalPrice} USD</div>
                </li>
              </ul>

            </div>
        </div>

    </div>
  </div>
}

export default OrderScreen;