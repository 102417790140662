import { ACTIVITY_LIST_REQUEST, ACTIVITY_LIST_SUCCESS, ACTIVITY_LIST_FAIL, ACTIVITY_DELETE_REQUEST, ACTIVITY_DELETE_SUCCESS, ACTIVITY_DELETE_FAIL, ACTIVITY_SAVE_REQUEST, ACTIVITY_SAVE_SUCCESS, ACTIVITY_SAVE_FAIL } from "../constants/activityConstants";

function activityListReducer(state= {activities:[]}, action){

    switch (action.type) {
        case    ACTIVITY_LIST_REQUEST:
            return  {loading: true};
        case    ACTIVITY_LIST_SUCCESS:
            return  {loading: false, activities: action.payload}
        case    ACTIVITY_LIST_FAIL:
            return  {loading: false, error: action.payload}
        default:
            return  state;
    }
}

function activityDeleteReducer(state = { activity: {} }, action) {
    switch (action.type) {
      case ACTIVITY_DELETE_REQUEST:
        return { loading: true };
      case ACTIVITY_DELETE_SUCCESS:
        return { loading: false, activity: action.payload, success: true };
      case ACTIVITY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
}
  
function activitySaveReducer(state = { activity: {} }, action) {
    switch (action.type) {
      case ACTIVITY_SAVE_REQUEST:
        return { loading: true };
      case ACTIVITY_SAVE_SUCCESS:
        return { loading: false, success: true, activity: action.payload };
      case ACTIVITY_SAVE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
}
  
export {activityListReducer, activityDeleteReducer, activitySaveReducer}